import "./husbandmedicals.scss";
import {useEffect, useState} from 'react'
import {db} from "../../firebase"
import { addDoc, collection, doc, getDocs, query, runTransaction, serverTimestamp } from 'firebase/firestore'
import EditHusbandMedicals from "./EditHusbandMedicals"
import { useParams } from "react-router-dom";


const Husband = () => {

  const [tasks, setTasks] = useState([]);
  const [createMedication, setCreateMedication] = useState("")
  const [createSurgery, setCreateSurgery] = useState("")
  const [checked, setChecked] = useState([]);

  const {userId} = useParams();

  const collectionRef = collection(db, "androcare/" + userId , "husbandMedicals")

  useEffect(()=>{
    const getTasks = async () => {
      const q = query(collectionRef)
      await getDocs(q).then((list)=>{
       let tasksData = list.docs.map((doc) => ({...doc.data(),
      id: doc.id}))
      setTasks(tasksData)
      setChecked(tasksData)
      }).catch((err) =>{
        console.log(err)
      })
    }
    getTasks()
  }, [])


    //Add Task Handler
 const submitTask = async (e) => {
  e.preventDefault();
  try {
    await addDoc(collectionRef, {
      medication: createMedication,
      surgery : createSurgery,
      isSickle: false,
      isDiabetes: false,
      isHypertension: false,
      isEpilepsy: false,
      isAsthma: false,
      is1Yes: false,
      is1No: false,
      is2Yes: false,
      is2No: false,
      is3Yes: false,
      is3No: false,
      is4Yes: false,
      is4No: false,
      is5Yes: false,
      is5No: false,
      is6Yes: false,
      is6No: false,
      isCigarette: false,
      isSmoking: false,
      isAlcohol: false,
      isCoffee: false,
      isSteriods: false,
      timeStamp: serverTimestamp()
    })
    window.location.reload();
  } catch (err) {
    console.log(err);
  }
}


// SickleCell
const checkBoxSickle = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isSickle: !state[indexToUpdate].isSickle,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isSickle;
    transaction.update(docRef, { isSickle: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}

// Diabetes
const checkBoxDiabetes = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isDiabetes: !state[indexToUpdate].isDiabetes,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isDiabetes;
    transaction.update(docRef, { isDiabetes: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Hypertension
const checkBoxHypertension = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isHypertension: !state[indexToUpdate].isHypertension,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isHypertension;
    transaction.update(docRef, { isHypertension: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Epilepsy
const checkBoxEpilepsy = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isEpilepsy: !state[indexToUpdate].isEpilepsy,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isEpilepsy;
    transaction.update(docRef, { isEpilepsy: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Asthma
const checkBoxAsthma = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isAsthma: !state[indexToUpdate].isAsthma,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isAsthma;
    transaction.update(docRef, { isAsthma: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}



// Cigarette
const checkBoxCigarete = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isCigarette: !state[indexToUpdate].isCigarette,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isCigarette;
    transaction.update(docRef, { isCigarette: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Smoking
const checkBoxSmoking = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isSmoking: !state[indexToUpdate].isSmoking,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isSmoking;
    transaction.update(docRef, { isSmoking: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Alcohol
const checkBoxAlcohol = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isAlcohol: !state[indexToUpdate].isAlcohol,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isAlcohol;
    transaction.update(docRef, { isAlcohol: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Coffee
const checkBoxCoffee = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isCoffee: !state[indexToUpdate].isCoffee,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isCoffee;
    transaction.update(docRef, { isCoffee: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Steriods
const checkBoxSteriods = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isSteriods: !state[indexToUpdate].isSteriods,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isSteriods;
    transaction.update(docRef, { isSteriods: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// 1.Yes
const checkBox1Yes = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      is1Yes: !state[indexToUpdate].is1Yes,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().is1Yes;
    transaction.update(docRef, { is1Yes: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// 1.No
const checkBox1No = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      is1No: !state[indexToUpdate].is1No,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().is1No;
    transaction.update(docRef, { is1No: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// 2.Yes
const checkBox2Yes = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      is2Yes: !state[indexToUpdate].is2Yes,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().is2Yes;
    transaction.update(docRef, { is2Yes: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// 2.No
const checkBox2No = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      is2No: !state[indexToUpdate].is2No,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().is2No;
    transaction.update(docRef, { is2No: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// 3.Yes
const checkBox3Yes = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      is3Yes: !state[indexToUpdate].is3Yes,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().is3Yes;
    transaction.update(docRef, { is3Yes: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// 3.No
const checkBox3No = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      is3No: !state[indexToUpdate].is3No,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().is3No;
    transaction.update(docRef, { is3No: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// 4.Yes
const checkBox4Yes = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      is4Yes: !state[indexToUpdate].is4Yes,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().is4Yes;
    transaction.update(docRef, { is4Yes: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// 4.No
const checkBox4No = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      is4No: !state[indexToUpdate].is4No,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().is4No;
    transaction.update(docRef, { is4No: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// 5.Yes
const checkBox5Yes = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      is5Yes: !state[indexToUpdate].is5Yes,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().is5Yes;
    transaction.update(docRef, { is5Yes: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}

// 5.No
const checkBox5No = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      is5No: !state[indexToUpdate].is5No,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().is5No;
    transaction.update(docRef, { is5No: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// 6.Yes
const checkBox6Yes = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      is6Yes: !state[indexToUpdate].is6Yes,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().is6Yes;
    transaction.update(docRef, { is6Yes: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}

// 6.No
const checkBox6No = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      is6No: !state[indexToUpdate].is6No,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "husbandMedicals" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().is6No;
    transaction.update(docRef, { is6No: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}

  return (
    <>
    <div className="husbandmedicals">
        Other History&#40;Husband&#41;
        <hr />

  {/* Modal Button */}
  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#humsModal">
    Add History
  </button>
   {/* Modal Button */}

   {tasks.map(({surgery, id, medication,  isSickle, isDiabetes, isHypertension, isEpilepsy, isAsthma, is1Yes, is1No, is2Yes, is2No, is3Yes, is3No, is4Yes, is4No, is5Yes, is5No, is6Yes, is6No, isCigarette, isSmoking, isAlcohol, isCoffee, isSteriods})=>
       <>
      <div className="medicalhistory"  key={id}>
        <div className="medicaldata">
        <div className="medicaldata1">
          <span>

          <span className="pim">
          <p><strong>Any history of the following:&nbsp;</strong></p>
          <input 
          type="checkbox" 
        defaultChecked={isSickle}
        onChange={(e) => checkBoxSickle(e)}  
        name={id}
          /> <p>Sickle cell</p> 


          <input type="checkbox" 
        defaultChecked={isDiabetes}
        onChange={(e) => checkBoxDiabetes(e)}  
        name={id}
          /> <p>Diabetes</p> 


          <input type="checkbox"
        defaultChecked={isHypertension}
        onChange={(e) => checkBoxHypertension(e)}  
        name={id}
          /> <p>Hypertension</p> 


          <input type="checkbox" 
        defaultChecked={isEpilepsy}
        onChange={(e) => checkBoxEpilepsy(e)}  
        name={id}
          /> <p>Epilepsy</p> 


          <input type="checkbox" 
        defaultChecked={isAsthma}
        onChange={(e) => checkBoxAsthma(e)}  
        name={id}
          /> <p>Asthma</p> 
          </span>

          <span className="pim2">
          <p><strong>Are you currently on any medication:&nbsp;</strong></p>
          <input type="checkbox" 
           defaultChecked={is1Yes}
        onChange={(e) => checkBox1Yes(e)}  
        name={id}
          /> <p>Yes</p>


          <input type="checkbox" 
           defaultChecked={is1No}
        onChange={(e) => checkBox1No(e)}  
        name={id}
          /> <p>No</p> 
          <p style={{fontSize:18, color:"#ca4200"}}><strong>If yes, specify:&nbsp;</strong> {medication} </p>
          </span>


          <span className="pim2">
          <p><strong>Do you react to any medication?&nbsp;</strong></p>
          <input type="checkbox" 
           defaultChecked={is2Yes}
        onChange={(e) => checkBox2Yes(e)}  
        name={id}
          /> <p>Yes</p>


          <input type="checkbox" 
           defaultChecked={is2No}
        onChange={(e) => checkBox2No(e)}  
        name={id}
          /> <p>No</p> 
          </span>


          <span className="pim2">
          <p><strong>Do you have any child in your previous relationships?&nbsp;</strong></p>
          <input type="checkbox" 
           defaultChecked={is3Yes}
        onChange={(e) => checkBox3Yes(e)}  
        name={id}
          /> <p>Yes</p>


          <input type="checkbox" 
           defaultChecked={is3No}
        onChange={(e) => checkBox3No(e)}  
        name={id}
          /> <p>No</p>
          </span>


          <span className="pim2">
          <p><strong>Do you have problem with erection?&nbsp;</strong></p>
          <input type="checkbox" 
           defaultChecked={is4Yes}
        onChange={(e) => checkBox4Yes(e)}  
        name={id}
          /> <p>Yes</p>


          <input type="checkbox" 
           defaultChecked={is4No}
        onChange={(e) => checkBox4No(e)}  
        name={id}
          /> <p>No</p>
          </span>

          <span className="pim">
          <p><strong>Do you take any of these:&nbsp;</strong></p>
          <input type="checkbox" 
           defaultChecked={isCigarette}
        onChange={(e) => checkBoxCigarete(e)}  
        name={id}
          /> <p>Cigarette</p> 


          <input type="checkbox" 
           defaultChecked={isSmoking}
        onChange={(e) => checkBoxSmoking(e)}  
        name={id}
          /> <p>Smoking</p> 


          <input type="checkbox" 
           defaultChecked={isAlcohol}
        onChange={(e) => checkBoxAlcohol(e)}  
        name={id}
          /> <p>Alcohol</p> 


          <input type="checkbox" 
           defaultChecked={isCoffee}
        onChange={(e) => checkBoxCoffee(e)}  
        name={id}
          /> <p>Coffee/Caffeine</p> 


          <input type="checkbox" 
           defaultChecked={isSteriods}
        onChange={(e) => checkBoxSteriods(e)}  
        name={id}
          /> <p>Steriods</p> 
          </span>

          <span className="pim2">
          <p><strong>Have you had a surgery done before:&nbsp;</strong></p>
          <input type="checkbox" 
           defaultChecked={is5Yes}
        onChange={(e) => checkBox5Yes(e)}  
        name={id}
          /> <p>Yes</p>


          <input type="checkbox" 
           defaultChecked={is5No}
        onChange={(e) => checkBox5No(e)}  
        name={id}
          /> <p>No</p> 
          <p style={{fontSize:18, color:"#ca4200"}}><strong>If yes, specify including year:&nbsp;</strong> {surgery} </p>
          </span>
          
          <span className="pim2">
          <p><strong>Are you exposed to dusts/chemicals/industrial fumes?&nbsp;</strong></p>
          <input type="checkbox" 
           defaultChecked={is6Yes}
        onChange={(e) => checkBox6Yes(e)}  
        name={id}
          /> <p>Yes</p>


          <input type="checkbox" 
           defaultChecked={is6No}
        onChange={(e) => checkBox6No(e)}  
        name={id}
          /> <p>No</p>
          </span>


          </span>
        </div>
        </div>
      </div>

      <div class="col-md-12 text-end">
      <button
      type='button'
      className='btn-pri'
      ><EditHusbandMedicals surgery={surgery} medication={medication} id={id} /></button>
      </div>
      </>
 )}
   {/* Ending Div */}
      </div>

        {/* Modal */}
        <div className="modal fade" id="humsModal" tabindex="-1" aria-labelledby="humsModalLabel" aria-hidden="true">
        <div className="modal-dialog">
        <form className="d-flex" onSubmit={submitTask}>
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="humsModalLabel">Other History</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            
            <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="pair-01">DO YOU REACT TO MED.</span>
        </div>
          <input
            type="text"
            className="form-control"
            placeholder="Do you react to medication.."
            onChange={(e) => setCreateMedication(e.target.value)}
          />
          </div>
      
          <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="pair-02">HAVE YOU DONE SURGERY BEFORE</span>
        </div>
          <input
            type="text"
            className="form-control"
            placeholder="Have you done surgery before..."
            onChange={(e) => setCreateSurgery(e.target.value)}
          />
          </div>
    
      
          
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="submit" className="btn btn-primary">Add Medicals</button>
            </div>
          </div>
          </form>
        </div>
      </div>
      
              </>
  )
}

export default Husband;