import "./reportscsa.scss";
import { useState, useEffect } from "react";
import {db, storage} from "../../firebase"
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage"
import { addDoc, collection, onSnapshot, serverTimestamp } from "firebase/firestore";
import { useParams } from "react-router-dom";

const initialState= {
    title: "",
  }

const ReportScsa = () => {

  const [data, setData] = useState(initialState);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [filesData, setFilesData] = useState([]);

  useEffect(() =>{
    const uploadFile = () => {
      const name = new Date().getTime + file.name;
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on("state_changed", (snapshot) =>{
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is Paused");
            break;
          case "running":
            console.log("Upload is Running");
          default:
            break;
        }
      }, (error) => {
        console.log(error)
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setData((prev) => ({...prev, sfaFile: downloadURL}))
        });
      }
      );
    }

    file && uploadFile()
  }, [file])

  const {userId} = useParams();


  const collectionRef = collection(db, "androcare/" + userId , "viewscsaResults")

   // Add Task Handler
   const submitTask = async (e) => {
    e.preventDefault(); 
    try { 
      await addDoc(collectionRef, {
        ...data,
        timeStamp : serverTimestamp()
      })
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }

  const handleChange = (e) =>{
    setData({...data, [e.target.name]: e.target.value});
  };

  useEffect(() =>{
    const unsub = onSnapshot(collectionRef, (snapshot) =>{
      let list = [];
      snapshot.docs.forEach((doc) => {
        list.push({id: doc.id, ...doc.data()})
      });
      setFilesData(list);
      console.log("scsaData", list)
    }, (error) =>{
      console.log(error);
    })

    return () => {
      unsub();
    }
  }, [])


  return (
    <>
    <div className='reportscsa'>
         <p>View SCSA Result</p>
    <hr />

    {/* Modal Button */}
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#viewScsaModal">
    Import SCSA
  </button>
  <br />
    <br />

  {filesData && filesData.map((item) =>(
      <div key={item.id}>
        <iframe src={item.sfaFile} frameborder="0" height="700px" width="100%"></iframe>
        <p>{item.title}</p>
      </div>
  ))}


        {/* Ending Div */}
        </div>

        
         {/* Modal */}
<div className="modal fade" id="viewScsaModal" tabindex="-1" aria-labelledby="viewScsaModalLabel" aria-hidden="true">
 <div className="modal-dialog">
 <form className="d-flex" onSubmit={submitTask}>
   <div className="modal-content">
     <div className="modal-header">
       <h1 className="modal-title fs-5" id="viewScsaModalLabel">SCSA FILE</h1>
       <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div className="modal-body">
     
     <form>
   <input
     type="text"
     className="form-control"
     placeholder="enter title here"
     onChange={handleChange}
     name="title"
     value={initialState.name}
     autoFocus
   />

   <input
     type="file"
     className="form-control"
     onChange={(e) => setFile(e.target.files[0])}
   />
   </form>
   
     </div>
     <div className="modal-footer">
       <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button type="submit" className="btn btn-primary" disabled={progress !== null && progress < 100} >Add SCSA</button>
     </div>
   </div>
   </form>
 </div>
</div>
        </>
  )
}

export default ReportScsa