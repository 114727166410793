import { useEffect, useState } from "react";
import "./spouse.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../datatablesource";
import { Link, useParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { Tooltip } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';




const Spouse = () => {

    const [data, setData] = useState([]);
    const [dataSet, setDataSet] = useState({
        regNo: "",
    });

    const {userId}  = useParams();
    let id = userId


    useEffect(() =>{
      let getUserDoc = async (id) =>{
        let docRef = doc(db, `androcare/${id}`);
        let docSnap = await getDoc(docRef);
        if (docSnap.exists) {
            let theData = docSnap.data();
        setDataSet(theData);
        console.log("actual data", theData)
        const getList = async () => {
            const currentValue = theData.regNo;
            console.log("currentValue set", currentValue)  
                    const collectionRef = collection(db, "androcare")
                  const q = query(collectionRef, where("regNo", "==", currentValue))
                  await getDocs(q).then((task)=>{
                   let medData = task.docs.map((doc) => ({...doc.data(),
                  id: doc.id}))
                  setData(medData)
                  console.log("medData", medData)
                  }).catch((err) =>{
                    console.log(err)
                  })
                }
                getList()
      }
    }
    getUserDoc(id)
    }, [id])


    const actionColumn = [
  
        { field: "action", headerName: "Action", width: 70, renderCell:(params)=>{
          return (
            <div className="cellAction">
      
              <Link to={`/users/${params.row.id}/casefolder`}>
              <Tooltip title="View Client" placement="bottom">
              <div className="viewButton"><VisibilityIcon/></div>
              </Tooltip>
              </Link>
              
            </div>
          )
        }}
      ]

  return (
    <div className="spouse">
        <h1>Case Folder</h1>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 2},
            },
          }}
      />

    </div>
  )
}

export default Spouse