import "./treatments.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import Tments from "../../components/tments/Tments";
import TrackTments from "../../components/tracktments/TrackTments";

const Treatments = () => {
  return (
    <div className='treatments'>
      <Sidebar/>
      <div className="treatmentsContainer">
      <Navbar/>
      <div className="tment">
      <Tments/>
      </div>
      <div className="ttments">
        <TrackTments/>
      </div>
      </div>
      </div>
  )
}

export default Treatments