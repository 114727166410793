import "./reports.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import Rep from './../../components/rep/Rep';
import ReportMcs from "../../components/reportmcs/ReportMcs";
import ReportMaleAssay from './../../components/reportmaleassay/ReportMaleAssay';
import ReportFemaleAssay from './../../components/reportfemaleassay/ReportFemaleAssay';
import ReportSemenAnalysis from "../../components/reportsemenanalysis/ReportSemenAnalysis";
import ReportEcs from './../../components/reportecs/ReportEcs';
import ReportAmh from './../../components/reportamh/ReportAmh';
import ReportHvs from './../../components/reporthvs/ReportHvs';
import ReportFbc from '../../components/reportfbc/ReportFbc';
import ReportLftB from "../../components/reportlftb/ReportLftB";
import ReportUrinalysis from "../../components/reporturinalysis/ReportUrinalysis";
import ReportScsa from "../../components/reportscsa/ReportScsa";

const Reports = () => {
  return (
    <div className='reports'>
    <Sidebar/>
    <div className="reportsContainer">
    <Navbar/>
    <div className="rep">
    <Rep/>
    </div>

    {/* Used for amh */}
    <div className="rmcs">
      <ReportMcs/>
    </div>

    <div className="rpecs">
      <ReportEcs/>
    </div>

   {/* Used for HVS mcs, Urine mcs, Urinalysis */}
    <div className="rphvs">
      <ReportHvs/>
    </div>

    <div className="rpurinalysis">
      <ReportUrinalysis/>
    </div>

    
    <div className="rmha">
      <ReportMaleAssay/>
    </div>

    <div className="rfmha">
      <ReportFemaleAssay/>
    </div>

    
    {/* Used for SFA */}
    <div className="rpsma">
      <ReportSemenAnalysis/>
    </div>

    <div className="rpscsa">
      <ReportScsa/>
    </div>


    {/* Used for Serology */}
    <div className="rpamh">
      <ReportAmh/>
    </div>

    <div className="rpfbca">
      <ReportFbc/>
    </div>


    <div className="rplftb">
      <ReportLftB/>
    </div>


   

    </div>
    </div>
  )
}

export default Reports