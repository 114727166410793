import "./cryop.scss"


const Cryop = () => {
  return (
    <div className="cryop">
      <h1>Cryo Preservations</h1>
    </div>
  )
}

export default Cryop