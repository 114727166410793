import React, {  useState } from 'react'
import { doc, updateDoc } from 'firebase/firestore'
import {db} from "../../firebase"
import 'bootstrap/dist/css/bootstrap.min.css';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { useParams } from 'react-router-dom';


const EditReportLftb = ({id,reportTitle,date,row1Header,row2Header,row3Header,subHeader,investigation01,result01,reference01,investigation02,result02,reference02,investigation03,result03,reference03,investigation04,result04,reference04,investigation05,result05,reference05,investigation06,result06,reference06,investigation07,result07,reference07,investigation08,result08,reference08,investigation09,result09,reference09,investigation10,result10,reference10}) => {


  const [createTitle, setCreateTitle] = useState(reportTitle)
  const [createRow1Header, setCreateRow1Header] = useState(row1Header)
  const [createRow2Header, setCreateRow2Header] = useState(row2Header)
  const [createRow3Header, setCreateRow3Header] = useState(row3Header)
  const [createSubHeader, setCreateSubHeader] = useState(subHeader)
  const [createDate, setCreateDate] = useState(date)
  const [createInvestigation01, setCreateInvestigation01] = useState(investigation01)
  const [createResult01, setCreateResult01] = useState(result01)
  const [createReference01, setCreateReference01] = useState(reference01)
  const [createInvestigation02, setCreateInvestigation02] = useState(investigation02)
  const [createResult02, setCreateResult02] = useState(result02)
  const [createReference02, setCreateReference02] = useState(reference02)
  const [createInvestigation03, setCreateInvestigation03] = useState(investigation03)
  const [createResult03, setCreateResult03] = useState(result03)
  const [createReference03, setCreateReference03] = useState(reference03)
  const [createInvestigation04, setCreateInvestigation04] = useState(investigation04)
  const [createResult04, setCreateResult04] = useState(result04)
  const [createReference04, setCreateReference04] = useState(reference04)
  const [createInvestigation05, setCreateInvestigation05] = useState(investigation05)
  const [createResult05, setCreateResult05] = useState(result05)
  const [createReference05, setCreateReference05] = useState(reference05)
  const [createInvestigation06, setCreateInvestigation06] = useState(investigation06)
  const [createResult06, setCreateResult06] = useState(result06)
  const [createReference06, setCreateReference06] = useState(reference06)
  const [createInvestigation07, setCreateInvestigation07] = useState(investigation07)
  const [createResult07, setCreateResult07] = useState(result07)
  const [createReference07, setCreateReference07] = useState(reference07)
  const [createInvestigation08, setCreateInvestigation08] = useState(investigation08)
  const [createResult08, setCreateResult08] = useState(result08)
  const [createReference08, setCreateReference08] = useState(reference08)
  const [createInvestigation09, setCreateInvestigation09] = useState(investigation09)
  const [createResult09, setCreateResult09] = useState(result09)
  const [createReference09, setCreateReference09] = useState(reference09)
  const [createInvestigation10, setCreateInvestigation10] = useState(investigation10)
  const [createResult10, setCreateResult10] = useState(result10)
  const [createReference10, setCreateReference10] = useState(reference10)

  const {userId} = useParams();
  
  const updateTask = async (e) => {
    e.preventDefault()
    try {
      const taskDocument = doc(db, "androcare/" + userId , "totalLftb", id);
      await updateDoc(taskDocument, {
        reportTitle : createTitle,
        date : createDate,
        row1Header : createRow1Header,
        row2Header : createRow2Header,
        row3Header : createRow3Header,
        subHeader : createSubHeader,
        investigation01 : createInvestigation01,
        result01 : createResult01,
        reference01 : createReference01,
        investigation02 : createInvestigation02,
        result02 : createResult02,
        reference02 : createReference02,
        investigation03 : createInvestigation03,
        result03 : createResult03,
        reference03 : createReference03,
        investigation04 : createInvestigation04,
        result04 : createResult04,
        reference04 : createReference04,
        investigation05 : createInvestigation05,
        result05 : createResult05,
        reference05 : createReference05,
        investigation06 : createInvestigation06,
        result06 : createResult06,
        reference06 : createReference06,
        investigation07 : createInvestigation07,
        result07 : createResult07,
        reference07 : createReference07,
        investigation08 : createInvestigation08,
        result08 : createResult08,
        reference08 : createReference08,
        investigation09 : createInvestigation09,
        result09 : createResult09,
        reference09 : createReference09,
        investigation10 : createInvestigation10,
        result10 : createResult10,
        reference10 : createReference10,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
    <button
      type="button"
      className="btn btn-primary btn-sm"
      data-bs-toggle="modal"
      data-bs-target={`#id${id}`}>
      <EditNoteRoundedIcon/>
    </button>
    <div
      className="modal fade"
      id={`id${id}`}
      tabIndex="-1"
      aria-labelledby="editLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editLabel" style={{color:"green"}}>
              Update FBC</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            
          <form>
   
   <div class="input-group">
   <div class="input-group-prepend">
     <span class="input-group-text" id="pair">REPORT TITLE</span>
   </div>
   <input
       type="text"
       className="form-control"
       placeholder="Enter Report Title"
       defaultValue={createTitle}
       onChange={(e) => setCreateTitle(e.target.value)}
     /> 
     </div>       

       <div class="input-group">
     <div class="input-group-prepend">
     <span class="input-group-text" id="pair">SUB HEADING</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter RBC Sub Heading"
                  defaultValue={createSubHeader}
                  onChange={(e) => setCreateSubHeader(e.target.value)}
                /> 
                </div>        
          
 
   <input
       type="date"
       className="form-control"
       defaultValue={createDate}
       onChange={(e) => setCreateDate(e.target.value)}
     /> 
 
 
   <div class="input-group">
   <div class="input-group-prepend">
     <span class="input-group-text" id="pair-1">Row1HeaderTitle, Row2HeaderTitle and Row3HeaderTitle</span>
   </div>
   <input
       type="text"
       className="form-control"
       defaultValue={createRow1Header}
       placeholder="Enter Row1HeaderTitle"
       onChange={(e) => setCreateRow1Header(e.target.value)}
     /> 
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row2HeaderTitle"
       defaultValue={createRow2Header}
       onChange={(e) => setCreateRow2Header(e.target.value)}
     />
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row3HeaderTitle"
       defaultValue={createRow3Header}
       onChange={(e) => setCreateRow3Header(e.target.value)}
     />
     </div>
 
 
   <div class="input-group">
   <div class="input-group-prepend">
     <span class="input-group-text" id="pair-1">Row1AData, Row1BData and Row1CData</span>
   </div>
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row1AData"
       defaultValue={createInvestigation01}
       onChange={(e) => setCreateInvestigation01(e.target.value)}
     /> 
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row1BData"
       defaultValue={createResult01}
       onChange={(e) => setCreateResult01(e.target.value)}
     />
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row1CData"
       defaultValue={createReference01}
       onChange={(e) => setCreateReference01(e.target.value)}
     />
     </div>


   <div class="input-group">
   <div class="input-group-prepend">
     <span class="input-group-text" id="pair-2">Row2AData, Row2BData and Row2CData</span>
   </div>
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row2AData"
       defaultValue={createInvestigation02}
       onChange={(e) => setCreateInvestigation02(e.target.value)}
     /> 
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row2BData"
       defaultValue={createResult02}
       onChange={(e) => setCreateResult02(e.target.value)}
     />
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row2CData"
       defaultValue={createReference02}
       onChange={(e) => setCreateReference02(e.target.value)}
     />
     </div>


     <div class="input-group">
   <div class="input-group-prepend">
     <span class="input-group-text" id="pair-3">Row3AData, Row3BData and Row3CData</span>
   </div>
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row3AData"
       defaultValue={createInvestigation03}
       onChange={(e) => setCreateInvestigation03(e.target.value)}
     /> 
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row3BData"
       defaultValue={createResult03}
       onChange={(e) => setCreateResult03(e.target.value)}
     />
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row3CData"
       defaultValue={createReference03}
       onChange={(e) => setCreateReference03(e.target.value)}
     />
     </div>


     <div class="input-group">
   <div class="input-group-prepend">
     <span class="input-group-text" id="pair-4">Row4AData, Row4BData and Row4CData</span>
   </div>
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row4AData"
       defaultValue={createInvestigation04}
       onChange={(e) => setCreateInvestigation04(e.target.value)}
     /> 
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row4BData"
       defaultValue={createResult04}
       onChange={(e) => setCreateResult04(e.target.value)}
     />
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row4CData"
       defaultValue={createReference04}
       onChange={(e) => setCreateReference04(e.target.value)}
     />
     </div>
   

     <div class="input-group">
   <div class="input-group-prepend">
     <span class="input-group-text" id="pair-5">Row5AData, Row5BData and Row5CData</span>
   </div>
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row5AData"
       defaultValue={createInvestigation05}
       onChange={(e) => setCreateInvestigation05(e.target.value)}
     /> 
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row5BData"
       defaultValue={createResult05}
       onChange={(e) => setCreateResult05(e.target.value)}
     />
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row5CData"
       defaultValue={createReference05}
       onChange={(e) => setCreateReference05(e.target.value)}
     />
     </div>

     <div class="input-group">
   <div class="input-group-prepend">
     <span class="input-group-text" id="pair-6">Row6AData, Row6BData and Row6CData</span>
   </div>
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row6AData"
       defaultValue={createInvestigation06}
       onChange={(e) => setCreateInvestigation06(e.target.value)}
     /> 
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row6BData"
       defaultValue={createResult06}
       onChange={(e) => setCreateResult06(e.target.value)}
     />
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row6CData"
       defaultValue={createReference06}
       onChange={(e) => setCreateReference06(e.target.value)}
     />
     </div>


     <div class="input-group">
   <div class="input-group-prepend">
     <span class="input-group-text" id="pair-7">Row7AData, Row7BData and Row7CData</span>
   </div>
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row7AData"
       defaultValue={createInvestigation07}
       onChange={(e) => setCreateInvestigation07(e.target.value)}
     /> 
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row7BData"
       defaultValue={createResult07}
       onChange={(e) => setCreateResult07(e.target.value)}
     />
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row7CData"
       defaultValue={createReference07}
       onChange={(e) => setCreateReference07(e.target.value)}
     />
     </div>
   


     <div class="input-group">
   <div class="input-group-prepend">
     <span class="input-group-text" id="pair-8">Row8AData, Row8BData and Row8CData</span>
   </div>
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row8AData"
       defaultValue={createInvestigation08}
       onChange={(e) => setCreateInvestigation08(e.target.value)}
     /> 
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row8BData"
       defaultValue={createResult08}
       onChange={(e) => setCreateResult08(e.target.value)}
     />
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row8CData"
       defaultValue={createReference08}
       onChange={(e) => setCreateReference08(e.target.value)}
     />
     </div>
   

     <div class="input-group">
   <div class="input-group-prepend">
     <span class="input-group-text" id="pair-9">Row9AData, Row9BData and Row9CData</span>
   </div>
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row9AData"
       defaultValue={createInvestigation09}
       onChange={(e) => setCreateInvestigation09(e.target.value)}
     /> 
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row9BData"
       defaultValue={createResult09}
       onChange={(e) => setCreateResult09(e.target.value)}
     />
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row9CData"
       defaultValue={createReference09}
       onChange={(e) => setCreateReference09(e.target.value)}
     />
     </div>
   
   
     <div class="input-group">
   <div class="input-group-prepend">
     <span class="input-group-text" id="pair-10">Row10AData, Row10BData and Row10CData</span>
   </div>
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row10AData"
       defaultValue={createInvestigation10}
       onChange={(e) => setCreateInvestigation10(e.target.value)}
     /> 
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row10BData"
       defaultValue={createResult10}
       onChange={(e) => setCreateResult10(e.target.value)}
     />
   <input
       type="text"
       className="form-control"
       placeholder="Enter Row10CData"
       defaultValue={createReference10}
       onChange={(e) => setCreateReference10(e.target.value)}
     />
     </div>
   
   
     </form>
 


          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={e => updateTask(e)}
            >Update Data</button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default EditReportLftb