import { useContext} from "react"
import { DarkModeContext } from "../../context/darkModeContext"
import "./navbar.scss"
import { DarkModeOutlined, FullscreenExitOutlined, LanguageOutlined, NotificationsNoneRounded, SearchOutlined } from "@mui/icons-material"
import { AuthContext } from "../../context/AuthContext"

const Navbar = () => {

  const {dispatch} = useContext(DarkModeContext)
  const {currentUser} = useContext(AuthContext)

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" 
          placeholder="Search docs..."
          />
          <SearchOutlined/>
        </div>
        <div className="items">
          <div className="item">
            <LanguageOutlined className="icon"/>
            English
          </div>

          <div className="item">
            <DarkModeOutlined className="icon icondark" onClick={()=> dispatch({type:"TOGGLE"})} />
          </div>

          <div className="item">
            <FullscreenExitOutlined className="icon"/>
          </div>

          <div className="item">
            <NotificationsNoneRounded className="icon"/>
          <div className="counter">4</div>
          </div>
{/* 
          <div className="item">
            <ChatOutlined className="icon"/>
          <div className="counter">8</div>
          </div> */}

          {/* <div className="item">
            <ListOutlined className="icon"/>
          </div> */}

        
          <div className="item">
              <img src={currentUser.photoURL} alt="" className="avatar" />
              <span>{currentUser.displayName}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar