import Sidebar from './../../components/sidebar/Sidebar';
import Navbar from './../../components/navbar/Navbar';
import "./notification.scss"
import Calendar from './../../components/calendar/Calendar';



const Notification = () => {
  return (
    <div className='notification'>
    <Sidebar/>
    <div className="notificationContainer">
    <Navbar/>
    <Calendar/>
    </div>
    </div>
  )
}

export default Notification