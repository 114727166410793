import "./viewreportfbc.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import logo from "../../bgImg/logo.png"
import HomeIcon from '@mui/icons-material/Home';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

const ViewReportFbc = () => {

  const [fbc, setFbc] = useState([]);
  const [users, setUsers] = useState([]);

  const {userId} = useParams();
  let id = userId
  
  // View respective users
useEffect(() => {
  const getUsers = async (id) =>{
    const docRef = doc(db, `androcare/${id}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let theData = docSnap.data();
      setUsers(theData)
      console.log("Document data:", theData);
    } else {
      setUsers({})
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };
  getUsers(id);
}, [id])

const {fbcId} = useParams();

// let id = serId

useEffect(() => {
    const getser = async () =>{
      const docRef = doc(db, "androcare/" + userId , "fullBloodCount", fbcId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setFbc(theData)
        console.log("Document data:", theData);
      } else {
        setFbc({})
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    getser(fbcId);
  }, [fbcId])



  return (
    <>
    <div className="viewreportfbc">
        <div className="headerfbc">
        <div>    
    <h1><img src={logo} alt="reportLogo" />&nbsp;Your IVF Clinic</h1>
    </div> 

    <span>
    <i><HomeIcon className="icon"/>&nbsp;Address: Your IVF Address
   </i>
   <br />
   <i></i>
    <br />
    <i><PhoneInTalkIcon className="icon"/>&nbsp;Contact: Your IVF Contact</i>
    <br />
    <i><AlternateEmailIcon className="icon"/>&nbsp;Email: Your IVF Email Address</i>
    <br />
    <i><OpenInBrowserIcon className="icon"/>&nbsp;Website: Your IVF Website</i>
    <br />
    <i><LocalHospitalIcon className="icon"/>&nbsp;Portal: Your IVF Portal</i>
    </span>
        </div>

        <hr/>
    
    <div className="fbcpatientsdetail">
        <div>
        <p><strong>Full Name:</strong>&nbsp;{users.title}&nbsp;{users.firstName}&nbsp;{users.lastName}</p>
        <p><strong>Email Address:</strong>&nbsp;{users.email}</p>
        <p><strong>Date:</strong>&nbsp;{fbc.date}</p>
        </div>
        <div>
        <p><strong>Age:</strong>&nbsp;{users.age}</p>
        <p><strong>Phone Number:</strong>&nbsp;{users.phoneNumber}</p>
        <p><strong>Occupation:</strong>&nbsp;{users.occupation}</p>
        </div>
        <div>
        <p><strong>Sex:</strong>&nbsp;{users.sex}</p>
        <p><strong>Registration No:</strong>&nbsp;{users.regNo}</p>
        <p><strong>State of Origin:</strong>&nbsp;{users.state}</p>
        </div>
    </div>

    <hr/>

    <div className="header">
          <h2 style={{textAlign: "center", fontWeight: "bold"}}>{fbc.reportTitle}</h2>
    </div>

    <div className="coveragefbc">
    <div className="overallfbc">
    <div className="headerfbc">
          <p>{fbc.row1Header}</p>
          <p>{fbc.row2Header}</p>  
          <p>{fbc.row3Header}</p>  
    </div>
    <div className="contentfbc">

      {/* Investigation vis result  RBC*/}
      <section style={{marginTop: "8px"}}>
      <section className="fbc" style={{marginLeft : "18px"}}>
      <u><p style={{fontWeight : "bold"}}>{fbc.subHeader01}</p></u>
     </section>
      </section>
     {/* Investigation vis result */}


      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="fbc" style={{marginLeft : "18px"}}>
      <p>{fbc.investigation01}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{fbc.result01}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "410px"}}>
      <p>{fbc.reference01}</p>
      </section>
      </section>
     {/* Investigation vis result */}

      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="fbc" style={{marginLeft : "18px"}}>
      <p>{fbc.investigation02}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{fbc.result02}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "410px"}}>
      <p>{fbc.reference02}</p>
      </section>
      </section>
     {/* Investigation vis result */}


        {/* Investigation vis result  WBC*/}
        <section style={{marginTop: "-10px"}}>
      <section className="fbc" style={{marginLeft : "18px"}}>
      <u><p style={{fontWeight : "bold"}}>{fbc.subHeader02}</p></u>
     </section>
      </section>
     {/* Investigation vis result */}

    
       {/* Investigation vis result */}
       <section style={{marginTop: "-38px"}}>
      <section className="fbc" style={{marginLeft : "18px"}}>
      <p>{fbc.investigation03}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{fbc.result03}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "410px"}}>
      <p>{fbc.reference03}</p>
      </section>
      </section>
     {/* Investigation vis result */}

      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="fbc" style={{marginLeft : "18px"}}>
      <p>{fbc.investigation04}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{fbc.result04}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "410px"}}>
      <p>{fbc.reference04}</p>
      </section>
      </section>
     {/* Investigation vis result */}

      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="fbc" style={{marginLeft : "18px"}}>
      <p>{fbc.investigation05}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{fbc.result05}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "410px"}}>
      <p>{fbc.reference05}</p>
      </section>
      </section>
     {/* Investigation vis result */}

      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="fbc" style={{marginLeft : "18px"}}>
      <p>{fbc.investigation06}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{fbc.result06}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "410px"}}>
      <p>{fbc.reference06}</p>
      </section>
      </section>
     {/* Investigation vis result */}

      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="fbc" style={{marginLeft : "18px"}}>
      <p>{fbc.investigation07}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{fbc.result07}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "410px"}}>
      <p>{fbc.reference07}</p>
      </section>
      </section>
     {/* Investigation vis result */}

      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="fbc" style={{marginLeft : "18px"}}>
      <p>{fbc.investigation08}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{fbc.result08}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "410px"}}>
      <p>{fbc.reference08}</p>
      </section>
      </section>
     {/* Investigation vis result */}


      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="fbc" style={{marginLeft : "18px"}}>
      <p>{fbc.investigation09}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{fbc.result09}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "410px"}}>
      <p>{fbc.reference09}</p>
      </section>
      </section>
     {/* Investigation vis result */}


      {/* Investigation vis result */}
      <section style={{marginTop: "8px"}}>
      <section className="fbc" style={{marginLeft : "18px"}}>
      <p>{fbc.investigation10}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{fbc.result10}</p>
      </section>
      <section className="fbc" style={{marginTop: "-41.0px", marginLeft: "410px"}}>
      <p>{fbc.reference10}</p>
      </section>
      </section>
     {/* Investigation vis result */}


     </div>
    </div>
    </div>

        {/* Ending Div */}
    </div>
        </>
  )
}

export default ViewReportFbc