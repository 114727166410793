import "./medicalhsy.scss"
import Mhistory from './../../components/mhistory/Mhistory';
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import Gynaecological from "../../components/gynaecological/Gynaecological";
import Obstetrics from "../../components/obstetrics/Obstetrics";
import Medicals from "../../components/medicals/Medicals";
import Husband from "../../components/husbandmedicals/Husband";

const MedicalHsy = () => {
  return (
    <div className='medicalhsy'>
      <Sidebar/>
      <div className="medicalhsyContainer">
      <Navbar/>
      <div className="mh">
      <Mhistory/>
      </div>
      <div className="gnhsy">
        <Gynaecological/>
      </div>
      <div className="obst">
        <Obstetrics/>
      </div>
      <div className="mdc">
        <Medicals/>
      </div>
      <div className="hums">
        <Husband/>
      </div>
      </div>
      </div>
  )
}

export default MedicalHsy