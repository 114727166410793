import "./mhistory.scss"

const Mhistory = () => {
  return (
    <div className="mhistory">
      <h1>Medical Information</h1>
      </div>
  )
}

export default Mhistory