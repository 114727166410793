import "./trackpcdures.scss"
import { addDoc, collection, getDocs, query, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditTrackPcdures from './EditTrackPcdures';

const TrackPcdures = () => {

  const [tasks, setTasks] = useState([]);
  const [createDate, setCreateDate] = useState("")
  const [createName, setCreateName] = useState("")
  const [createDoctor, setCreateDoctor] = useState("")
  const [createStatus, setCreateStatus] = useState("")

  const {userId} = useParams();

  const collectionRef = collection(db, "androcare/" + userId , "procedures")

  useEffect(()=>{
    const getTasks = async () => {
      const q = query(collectionRef)
      await getDocs(q).then((task)=>{
       let tasksData = task.docs.map((doc) => ({...doc.data(),
      id: doc.id}))
      setTasks(tasksData)
      console.log("taskData", tasksData)
      }).catch((err) =>{
        console.log(err)
      })
    }
    getTasks()
  }, [])


  // Add Task Handler
  const submitTask = async (e) => {
    e.preventDefault();  
    try {
      await addDoc(collectionRef, {
        name: createName,
        doctor: createDoctor,
        date: createDate,
        status: createStatus,
        timeStamp: serverTimestamp()
      })
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <div className="trackpcdures">
    Procedures Allocated

       <hr />

 {/* Modal Button */}
 <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
    Add Procedure
    </button>

     {/* Table Components */}
     <div style={{marginTop: "30px"}}>
      <table className="styled-table">
        <thead>
          <tr>
            <th style={{textAlign: "center"}}>Procedure Name</th>
            <th style={{textAlign: "center"}}>Date</th>
            <th style={{textAlign: "center"}}>Consultant Doctor</th>
            <th style={{textAlign: "center"}}>Status</th>
            <th style={{textAlign: "center"}}>Action</th>
          </tr>
        </thead>
        <tbody>
        
      {tasks.map(({id, name, date, doctor, status}) =>
          <tr key={id}>
                      <td>{name}</td>
                      <td>{date}</td>
                      <td>{doctor}</td>
                      <td>{status}</td>
                      <td> <button 
        type='button'
        className='icon'
        ><EditTrackPcdures id={id} name={name} date={date} doctor={doctor} status={status} />
        </button></td>
          </tr>
      )}

        </tbody>

    {/* Mapping those data in the doc alternative */}
        {/* <tbody>
          {Object.keys(tasks).map((id)=> {
            return(
              <tr>
                <td>{tasks[id].name}</td>
                <td>{tasks[id].date}</td>
                <td>{tasks[id].docto}</td>
                <td>{tasks[id].status}</td>
              </tr>
            )
          })}
        </tbody> */}
        {/* Mapping those data in the doc alternative */}


      </table>
     </div>
 {/* Table Components */}


       {/* Modal */}
 <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
  <form className="d-flex" onSubmit={submitTask}>
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Procedures</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      
    <input
      type="text"
      className="form-control"
      placeholder="enter procedure here..."
      onChange={(e) => setCreateName(e.target.value)}
      size="3080"
    />
    <input
      type="date"
      className="form-control"
      onChange={(e) => setCreateDate(e.target.value)}
    />
    <input
      type="text"
      placeholder="Consultant doctor.."
      className="form-control"
      onChange={(e) => setCreateDoctor(e.target.value)}
      size="3080"
    />
    <input
      type="text"
      placeholder="enter status here.."
      className="form-control"
      onChange={(e) => setCreateStatus(e.target.value)}
      size="3080"
    />

    
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary">Add Procedure</button>
      </div>
    </div>
    </form>
  </div>
</div>
   


        </div>
  )
}

export default TrackPcdures
