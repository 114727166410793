import "./reps.scss"

const Rep = () => {
  return (
    <div className="reps">
      <h1>Reports</h1> 
      </div>
  )
}

export default Rep