import { useParams } from "react-router-dom";
import "./casefile.scss"
import { doc, getDoc} from 'firebase/firestore';
import { useEffect, useState } from "react";
import { db } from '../../firebase';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';


const CaseFile = () => {
  const [users, setUsers] = useState([]);

  // fetch id from the url
  const {userId}  = useParams();
  let id = userId

// View respective users
  useEffect(() => {
    const getUsers = async (id) =>{
      const docRef = doc(db, `androcare/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData)
        console.log("Document data:", theData);
      } else {
        setUsers({})
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    getUsers(id);
  }, [id])
  

  console.log("users", users)

  return (
    <>
    <div className='casefile'>
      <div className="profile">
        <img src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg" alt="userimg" />
        <div className="userinfo">
          <PersonIcon className="icon"/>
          <h1>{users.title}</h1>
          <h1>{users.firstName}</h1>
          <h1>{users.lastName}</h1>
        </div>
        <div className="userinfo">
          <EmailIcon className="icon"/>
          <h2>{users.email}</h2>
        </div>
        <div className="userinfo">
          <LocalHospitalIcon className="icon3"/>
          <h3>{users.reffBy}</h3>
        </div>
        <div className="userinfo">
          <LocalPhoneIcon className="icon4"/>
          <h4>{users.phoneNumber}</h4>
        </div>
      </div>

      <div className="userprofile">
      <div className="username">
        <div className="usersinfos">
          <span>
        <h1><strong>Reg No:</strong> {users.regNo}</h1>
        <h1><strong>Age:</strong> {users.age}</h1>
        <h1><strong>Status:</strong> {users.status}</h1>
        <h1><strong>Occupation:</strong> {users.occupation}</h1>
        <h1><strong>Date of Birth:</strong> {users.birth}</h1>
          </span>
        </div>
      </div>

      <div className="details">
      <div className="usersinfos">
          <span>
        <h1><strong>Sex:</strong> {users.sex}</h1>
        <h1><strong>State of Origin:</strong> {users.state}</h1>
        <h1><strong>Infertility Duration:</strong> {users.duration}</h1>
        <h1><strong>How Did you find us:</strong> {users.finding}</h1>
        <h1><strong>Address:</strong> {users.address}</h1>
          </span>
        </div>
      </div>

      
      </div>

       </div>
       </>
       
       )
}

export default CaseFile