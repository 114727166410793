import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { db } from '../../firebase';


const EditInvngs = ({id, name, date, doctor, status}) => {

    const [createDate, setCreateDate] = useState(date)
    const [createName, setCreateName] = useState(name)
    const [createDoctor, setCreateDoctor] = useState(doctor)
    const [createStatus, setCreateStatus] = useState(status)


    const {userId} = useParams();
    console.log(userId)


  const updateData = async (e) => {
    e.preventDefault()
    try {
      const taskDocument = doc(db, "androcare/" + userId , "investigations", id);
      await updateDoc(taskDocument, {
        name: createName,
        doctor: createDoctor,
        date: createDate,
        status: createStatus,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }


  }
  return (
   <>
        {/* Modal Button */}
        <button
        type="button"
        className="btn btn-primary btn-sm"
        data-bs-toggle="modal"
        data-bs-target={`#id${id}`}>
        <EditNoteIcon/>
      </button>

        
      <div
        className="modal fade"
        id={`id${id}`}
        tabIndex="-1"
        aria-labelledby="editLabel"
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editLabel" style={{color:"grey"}}>
                Update Investigations</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              <input
      type="text"
      className="form-control"
      placeholder="enter investigation here..."
      defaultValue={createName}
      onChange={(e) => setCreateName(e.target.value)}
      size="3080"
    />
    <input
      type="date"
      className="form-control"
      defaultValue={createDate}
      onChange={(e) => setCreateDate(e.target.value)}
    />
    <input
      type="text"
      placeholder="Consultant doctor.."
      className="form-control"
      defaultValue={createDoctor}
      onChange={(e) => setCreateDoctor(e.target.value)}
      size="3080"
    />
    <input
      type="text"
      placeholder="enter status here.."
      className="form-control"
      defaultValue={createStatus}
      onChange={(e) => setCreateStatus(e.target.value)}
      size="3080"
    />
            

            </div>

              <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={e => updateData(e)}
              >Update Investigation</button>
            </div>
          </div>
        </div>
      </div>

   </>
  )
}

export default EditInvngs