import "./reviewresult.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import Ror from "../../components/ror/Ror";
import TrackReview from "../../components/trackreview/TrackReview";


const ReviewResults = () => {
  return (
    <div className="reviewr">
        <Sidebar/>
        <div className="reviewContainer">
            <Navbar/>
            <div className="reviewsresult">
            <Ror/>
            </div>
            <div className="treview">
              <TrackReview/>
            </div>
        </div>
        </div>
  )
}

export default ReviewResults