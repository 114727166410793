import "./procedures.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import Pcdures from './../../components/pcdures/Pcdures';
import TrackPcdures from "../../components/trackpcdures/TrackPcdures";

const Procedures = () => {
  return (
    <div className='procedures'>
    <Sidebar/>
    <div className="proceduresContainer">
    <Navbar/>
    <div className="prcudures">
    <Pcdures/>
    </div>
    <div className="tpcdures">
      <TrackPcdures/>
    </div>
    </div>
    </div>
  )
}

export default Procedures