import "./bellclients.scss"

const BellClients = () => {
  return (
    <div className="bellclients">
      <h1>Appointment</h1>
      </div>
  )
}

export default BellClients