import Sidebar from './../../components/sidebar/Sidebar';
import Navbar from './../../components/navbar/Navbar';
import "./chat.scss"


import Texting from '../../components/texting/Texting'
const Chat = () => {
  return (
    <div className='chat'>
      <Sidebar/>
      <div className="chatContainer">
      <Navbar/>
      <Texting/>
      </div>
      </div>
  )
}

export default Chat