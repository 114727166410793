import "./roommanager.scss"

const RoomManager = ({room}) => {
  return (
    <>
    <div className='roommanager'>
      <h1>{room}</h1>
    </div>
    </>
  )
}

export default RoomManager