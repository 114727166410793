import { useContext } from "react"
import "./navchatbar.scss"
import { AuthContext } from "../../../context/AuthContext"


const NavChatbar = () => {
  const {currentUser} = useContext(AuthContext)
  return (
    <div className="navchatbar">
      <span className="logo">Androcare</span>
      <div className="user">
        <img src={currentUser.photoURL} alt="" />
        <span>{currentUser.displayName}</span>
      </div>
      </div>
  )
}

export default NavChatbar