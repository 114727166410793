import "./investigations.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import Invgs from "../../components/invgs/Invgs";
import TrackInvgs from "../../components/trackinvgs/TrackInvgs";


const Investigations = () => {
  return (
    <div className='investigations'>
    <Sidebar/>
    <div className="investigationsContainer">
    <Navbar/>
    <div className="invges">
    <Invgs/>
    </div>
    <div className="tinvges">
      <TrackInvgs/>
    </div>
    </div>
    </div>
  )
}

export default Investigations