import { KeyboardArrowUpOutlined, PersonOutlined } from "@mui/icons-material"
import PeopleIcon from '@mui/icons-material/People';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";



import "./widget.scss"

// fetch from db


const Widget = ({ type }) => {
  const [amount, setAmount] = useState(null);
  const [diff, setDiff] = useState(null);

  let data;

    switch(type){
      case "client":
          data={
              title:"CLIENTS",
              query: "androcare",
              link: "See all users",
              icon: <PersonOutlined className="icon"
              style={{
                color: "crimson",
                backgroundColor: "rgba(255, 0, 0, 0.2)",
              }}
              />
          };
          break;
            case "incidents":
              data={
                  title:"INCIDENTS",
                  query: "incidents",
                  link: "View Incidents Log",
                  icon: <PeopleIcon className="icon"
                  style={{color: "goldenrod",
                  backgroundColor: "rgba(218, 165, 32, 0.2)",}}
                  />
              };
              break;
              case "alerts":
                data={
                    title:"ALERTS",
                    query: "alerts",
                    link: "Get Notified",
                    icon: <NotificationsActiveOutlinedIcon className="icon"
                    style={{color: "green", 
                    backgroundColor: "rgba(133, 255, 159)",}}
                    />
                };
                break;
                case "task":
                  data={
                      title:"WAITING ROOM",
                      query: "tasks",
                      link: "Pending",
                      icon: <HourglassTopIcon className="icon"
                      style={{
                        color: "purple",
                        backgroundColor: "rgba(248, 116, 252)",
                      }}
                      />
                  };
                  break;
                  default:
                    break;
    }


    useEffect(() => {
      const fetchData = async () => {
        const today = new Date();
        const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));
        const prevMonth = new Date(new Date().setMonth(today.getMonth() - 2));
  
        const lastMonthQuery = query(
          collection(db, data.query),
          where("timeStamp", "<=", today),
          where("timeStamp", ">", lastMonth)
        );
  
        const prevMonthQuery = query(
          collection(db, data.query),
          where("timeStamp", "<=", lastMonth),
          where("timeStamp", ">", prevMonth)
        );

        const lastMonthData = await getDocs(lastMonthQuery);
        const prevMonthData = await getDocs(prevMonthQuery);

        setAmount(lastMonthData.docs.length);
      setDiff(
        ((lastMonthData.docs.length - prevMonthData.docs.length) / prevMonthData.docs.length) *
          100
      );
      }
      fetchData();
    }, []);
  


  return (
    <div className="widget">
        <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "$"} {amount}
          </span> {/*db*/}
        <span className="link"> {data.link} </span>
        </div>
        <div className="right">
        <div className="percentage positive">
            <KeyboardArrowUpOutlined/>
            {/* {diff} % */}
        </div>
        {data.icon}
        </div>
    </div>
  )
}

export default Widget