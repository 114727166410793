import "./nursenotes.scss";

const NurseNotes = () => {
  return (
    <div className='nursenotes'>
      <h1>Nursing Notes</h1>
      </div>
  )
}

export default NurseNotes