import { FullscreenExitOutlined, LanguageOutlined, ListOutlined, SearchOutlined } from "@mui/icons-material";
import "./navbar.scss";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import PersonIcon from '@mui/icons-material/Person';
import { useParams } from "react-router-dom";


const Navbar = () => {

  const [users, setUsers] = useState([]);

  const {userId}  = useParams();
  let id = userId

  // View respective users
  useEffect(() => {
    const getUsers = async (id) =>{
      const docRef = doc(db, `androcare/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData)
        console.log("Document data:", theData);
      } else {
        setUsers({})
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    getUsers(id);
  }, [id])


  return (
    <div className="navbarP">
    <div className="wrapper">
      <div className="search">
        <input type="text" 
        placeholder="Search docs..."
        />
        <SearchOutlined/>
      </div>

      <div className="usersc">
          <p> <PersonIcon style={{color:"#ca4200"}}/> {users.title} {users.firstName} {users.lastName}</p>
    </div>

      <div className="items">
        <div className="item">
          <LanguageOutlined className="icon"/>
          English
        </div>

        <div className="item">
          <FullscreenExitOutlined className="icon"/>
        </div>

        <div className="item">
          <ListOutlined className="icon"/>
        </div>

      {/* Image for user logged in */}
        {/* <div className="item">
            <img src="" alt="" className="avatar" />
        </div> */}
      </div>
    </div>
  </div>
  )
}

export default Navbar