import Messages from "../messages/Messages";
import "./schat.scss"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Inputchat from './../inputchat/Inputchat';
import { ChatContext } from "../../../context/ChatContext";
import { useContext } from "react";


const Schat = () => {
  
  const {data} = useContext(ChatContext);

  return (
    <div className="schat">
      <div className="chatInfo">
        <span>{data.user?.displayName}</span>
        <div className="chatIcons">
          <MoreHorizIcon className="icon"/>
        </div>
      </div>
        <Messages/>
        <Inputchat/>
      </div>
  )
}

export default Schat