import "./sidebar.scss"

import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import VaccinesIcon from '@mui/icons-material/Vaccines';import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import InventoryIcon from '@mui/icons-material/Inventory';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import NotesIcon from '@mui/icons-material/Notes';
import RateReviewIcon from '@mui/icons-material/RateReview';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';

import { Link, useParams} from 'react-router-dom';
import logo from "../../bgImg/logo.png"

const Sidebar = () => {

  const {userId} = useParams();
  
  return (
    <div className='sidebarP'>
    <div className="top">
    <Link to="/" style={{ textDecoration: "none" }}>
    <span className="logo">
    <img src={logo} alt="logo" /> 
      Fertilizon
      </span>
    </Link>
    </div>

    
    <hr />
    <div className="center">
      <ul>
        <p className="title">MAIN</p>

        <Link to={`/users/${userId}/casefolder`} style={{ textDecoration: "none" }}>
        <li>
        <FolderCopyIcon className="icon"/>
          <span>Case Folder</span> 
        </li>
        </Link>

        <Link to={`/users/${userId}/medicalhistory`} style={{ textDecoration: "none" }} >
        <p className="title">Lists</p>
        <li>
          <MedicalInformationIcon className="icon"/>
        <span>Medical Information</span> 
        </li>
        </Link>

        <Link to={`/users/${userId}/consultationnotes`} style={{ textDecoration: "none" }}>
        <li>
        <DocumentScannerIcon className="icon"/>
        <span>Consultation Notes</span> 
        </li>
        </Link>

        <Link to={`/users/${userId}/nursingnotes`} style={{ textDecoration: "none" }}>
        <li>
        <NotesIcon className="icon"/>
        <span>Nursing Notes</span> 
        </li>
        </Link>


        <Link to={`/users/${userId}/reminder`} style={{ textDecoration: "none" }}>
        <li>
          <NotificationsActiveIcon className="icon"/>
        <span>Appointment</span> 
        </li>
        </Link>

        <p className="title">Findings</p>
        <Link to={`/users/${userId}/investigations`} style={{ textDecoration: "none" }}>
        <li>
          <VaccinesIcon className="icon"/>
        <span>Investigations</span> 
        </li>
        </Link>

        <Link to={`/users/${userId}/procedures`} style={{ textDecoration: "none" }}>
        <li>
          <HealthAndSafetyIcon className="icon"/>
        <span>Procedures</span> 
        </li>
        </Link>
        
        <Link to={`/users/${userId}/treatments`} style={{ textDecoration: "none" }}>
        <li>
          <MonitorHeartIcon className="icon"/>
        <span>Treatments</span> 
        </li>
        </Link>

        <Link to={`/users/${userId}/cryopreservations`} style={{ textDecoration: "none" }}>
        <li>
          <InventoryIcon className="icon"/>
        <span>Cryo Preservations</span> 
        </li>
        </Link>

        <Link to={`/users/${userId}/reviewofresult`} style={{ textDecoration: "none" }}>
        <li>
          <RateReviewIcon className="icon"/>
        <span>Review Of Result</span> 
        </li>
        </Link>

        <p className="title">Summary</p>

        <Link to={`/users/${userId}/pharmacy`} style={{ textDecoration: "none" }}>
        <li>
        <LocalPharmacyIcon className="icon"/>
        <span>Pharmacy Drugs</span> 
        </li>
        </Link>

        <Link to={`/users/${userId}/billing`} style={{ textDecoration: "none" }}>
        <li>
        <LocalAtmIcon className="icon"/>
        <span>Billing</span> 
        </li>
        </Link>


        <Link to={`/users/${userId}/reports`} style={{ textDecoration: "none" }}>
        <li>
        <SummarizeIcon className="icon"/>
        <span>Reports</span> 
        </li>
        </Link>
      </ul>
    </div>

    </div>
  )
}

export default Sidebar