import "./reporthvs.scss";
import { addDoc, collection, getDocs, query, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PreviewIcon from '@mui/icons-material/Preview';
import EditReportHvs from "./EditReportHvs";


const ReportHvs = () => {

  const mcsCTitles = ["HVS M/C/S REPORT", "URINE M/C/S REPORT"]

  const mcsCSubheading = ["MICROBIOLOGY"]

  const microscopyHeader = ["Microscopy"]

  const microscopyCells = ["Pus cell", "Epitheila cell", "Bacteria cell", "Calcium oxalate"]

  const microscopyValues = ["2-4", "3-7", "+", "Nil"]

  const cultureCTitle = ["CULTURE"]

  const cultureCSubHeading = ["Yielded moderate growth of Escherichia coli spp after 24hrs of incubation at 37 o/c"]

  const cultureC1Header = ["ANTIBIOTICS"]

  const cultureC2Header = ["SENSITIVITY"]

  const crow1listings = ["PERFLOXACIN", "CIPROFLOXACIN", "SEPTRIN", "AMPICLOX", "ERYTHROMYCIN", "AMOXCILLIN", "GENTAMYCIN", "STREPTOMYCIN", "ROCEPHINE", "ZINNART"]

  const crow2listings = ["R", "S", "S+++", "S++", "S+"]

  const [tasks, setTasks] = useState([]);
  const [mcsTitle, setMcsTitle] = useState("")
  const [mcsSubheading, setMcsSubheading] = useState("")
  const [microscopyheader, setMicroscopyHeader] = useState("")
  const [microscopycell01, setMicroscopyCell01] = useState("")
  const [microscopycell02, setMicroscopyCell02] = useState("")
  const [microscopycell03, setMicroscopyCell03] = useState("")
  const [microscopycell04, setMicroscopyCell04] = useState("")
  const [microscopyvalue01, setMicroscopyValue01] = useState("")
  const [microscopyvalue02, setMicroscopyValue02] = useState("")
  const [microscopyvalue03, setMicroscopyValue03] = useState("")
  const [microscopyvalue04, setMicroscopyValue04] = useState("")
  const [cultueTitle, setCultureTitle] = useState("")
  const [cultueSubHeading, setCultureSubHeading] = useState("")
  const [cultue1Header, setCulture1Header] = useState("")
  const [cultue2Header, setCulture2Header] = useState("")
  const [createAntibiotics01, setCreateAntibiotics01] = useState("")
  const [createSensitivity01, setCreateSensitivity01] = useState("")
  const [createAntibiotics02, setCreateAntibiotics02] = useState("")
  const [createSensitivity02, setCreateSensitivity02] = useState("")
  const [createAntibiotics03, setCreateAntibiotics03] = useState("")
  const [createSensitivity03, setCreateSensitivity03] = useState("")
  const [createAntibiotics04, setCreateAntibiotics04] = useState("")
  const [createSensitivity04, setCreateSensitivity04] = useState("")
  const [createAntibiotics05, setCreateAntibiotics05] = useState("")
  const [createSensitivity05, setCreateSensitivity05] = useState("")
  const [createAntibiotics06, setCreateAntibiotics06] = useState("")
  const [createSensitivity06, setCreateSensitivity06] = useState("")
  const [createAntibiotics07, setCreateAntibiotics07] = useState("")
  const [createSensitivity07, setCreateSensitivity07] = useState("")
  const [createAntibiotics08, setCreateAntibiotics08] = useState("")
  const [createSensitivity08, setCreateSensitivity08] = useState("")
  const [createAntibiotics09, setCreateAntibiotics09] = useState("")
  const [createSensitivity09, setCreateSensitivity09] = useState("")
  const [createAntibiotics10, setCreateAntibiotics10] = useState("")
  const [createSensitivity10, setCreateSensitivity10] = useState("")
  const [createDate, setCreateDate] = useState("")
  const [checkedBy, setCheckedby] = useState("")

  const {userId} = useParams();
  
  const collectionRef = collection(db, "androcare/" + userId , "hvsandurine")

  useEffect(()=>{ 
    const getTasks = async () => {
      const q = query(collectionRef)
      await getDocs(q).then((task)=>{
       let tasksData = task.docs.map((doc) => ({...doc.data(),
      id: doc.id}))
      setTasks(tasksData)
      }).catch((err) =>{
        console.log(err)
      })
    }
    getTasks()
  }, [])

    
     // Add Task Handler
     const submitTask = async (e) => {
      e.preventDefault();  
      try {
        await addDoc(collectionRef, {
          dmcsCTitles : mcsTitle,
          date : createDate,
          dmcsCSubheading : mcsSubheading,
          dmicroscopyHeader : microscopyheader,
          dmicroscopyCells01 : microscopycell01,
          dmicroscopyCells02 : microscopycell02,
          dmicroscopyCells03 : microscopycell03,
          dmicroscopyCells04 : microscopycell04,
          dmicroscopyValues01  : microscopyvalue01,
          dmicroscopyValues02  : microscopyvalue02,
          dmicroscopyValues03  : microscopyvalue03,
          dmicroscopyValues04  : microscopyvalue04,
          dcultureCTitle : cultueTitle,
          dcultureCSubHeading : cultueSubHeading,
          dcultureC1Header : cultue1Header,
          dcultureC2Header : cultue2Header,
          antibiotics01 : createAntibiotics01,
          sensitivity01 : createSensitivity01,
          antibiotics02 : createAntibiotics02,
          sensitivity02 : createSensitivity02,
          antibiotics03 : createAntibiotics03,
          sensitivity03 : createSensitivity03,
          antibiotics04 : createAntibiotics04,
          sensitivity04 : createSensitivity04,
          antibiotics05 : createAntibiotics05,
          sensitivity05 : createSensitivity05,
          antibiotics06 : createAntibiotics06,
          sensitivity06 : createSensitivity06,
          antibiotics07 : createAntibiotics07,
          sensitivity07 : createSensitivity07,
          antibiotics08 : createAntibiotics08,
          sensitivity08 : createSensitivity08,
          antibiotics09 : createAntibiotics09,
          sensitivity09 : createSensitivity09,
          antibiotics10 : createAntibiotics10,
          sensitivity10 : createSensitivity10,
          checkedby : checkedBy,
          timeStamp: serverTimestamp()
        })
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }

  return (
    <>
    <div className='reporthvs'>
         <p>Microbiology&#40;HVS M/C/S, URINE M/C/S&#41;</p>

         <hr />

    {/* Modal Button */}
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#totalmcsModal">
    Add M/C/S
    </button>   



     {/* Mapped Area */}
     {tasks.map(({id,dmcsCTitles,date,dmcsCSubheading,dmicroscopyHeader,dmicroscopyCells01,dmicroscopyCells02,dmicroscopyCells03,dmicroscopyCells04,dmicroscopyValues01,dmicroscopyValues02,dmicroscopyValues03,dmicroscopyValues04,dcultureCTitle,dcultureCSubHeading,dcultureC1Header,dcultureC2Header,antibiotics01, sensitivity01,antibiotics02,sensitivity02,antibiotics03,sensitivity03,antibiotics04,sensitivity04,antibiotics05,sensitivity05,antibiotics06,sensitivity06,antibiotics07,sensitivity07,antibiotics08,sensitivity08,antibiotics09,sensitivity09,antibiotics10,sensitivity10,checkedby})=>
    <>
    <div className="coveragehvs">
    <div className="overallhvs" key={id}>
    <div className="headerhvs">
 
    </div>
    <div className="contenthvs">

      {/* Microscopy plus cells */}
      <section style={{marginTop: "8px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <u><p style={{fontWeight : "bold"}}>{dmicroscopyHeader}</p></u>
     </section>
      </section>
     {/* Microscopy plus cells */}


      {/* Microscopy plus cells */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{dmicroscopyCells01}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyValues01}</p>
      </section>
      </section>
     {/* Microscopy plus cells */}


      {/* Microscopy plus cells */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{dmicroscopyCells02}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyValues02}</p>
      </section>
      </section>
     {/* Microscopy plus cells */}


      {/* Microscopy plus cells */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{dmicroscopyCells03}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyValues03}</p>
      </section>
      </section>
     {/* Microscopy plus cells */}


      {/* Microscopy plus cells */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{dmicroscopyCells04}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyValues04}</p>
      </section>
      </section>
     {/* Microscopy plus cells */}

     </div>
    </div>
    </div>


    <div className="coveragehvs">
    <div className="overallhvs" key={id}>
    <div className="headerhvs">
          <p>{dcultureC1Header}</p>
          <p>{dcultureC2Header}</p>  
    </div>
    <div className="contenthvs">

      {/* Antibiotics plus Sensitivity*/}
      <section style={{marginTop: "8px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{antibiotics01}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{sensitivity01}</p>
      </section>
      </section>
     {/* Antibiotics plus Sensitivity */}


      {/* Antibiotics plus Sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{antibiotics02}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{sensitivity02}</p>
      </section>
      </section>
     {/* Antibiotics plus Sensitivity */}


      {/* Antibiotics plus Sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{antibiotics03}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{sensitivity03}</p>
      </section>
      </section>
     {/* Antibiotics plus Sensitivity */}


      {/* Antibiotics plus Sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{antibiotics04}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{sensitivity04}</p>
      </section>
      </section>
     {/* Antibiotics plus Sensitivity */}


      {/* Antibiotics plus Sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{antibiotics05}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{sensitivity05}</p>
      </section>
      </section>
     {/* Antibiotics plus Sensitivity */}


      {/* Antibiotics plus Sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{antibiotics06}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{sensitivity06}</p>
      </section>
      </section>
     {/* Antibiotics plus Sensitivity */}


      {/* Antibiotics plus Sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{antibiotics07}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{sensitivity07}</p>
      </section>
      </section>
     {/* Antibiotics plus Sensitivity */}


      {/* Antibiotics plus Sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{antibiotics08}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{sensitivity08}</p>
      </section>
      </section>
     {/* Antibiotics plus Sensitivity */}


      {/* Antibiotics plus Sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{antibiotics09}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{sensitivity09}</p>
      </section>
      </section>
     {/* Antibiotics plus Sensitivity */}


      {/* Antibiotics plus Sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="hvs" style={{marginLeft : "18px"}}>
      <p>{antibiotics10}</p>
      </section>
      <section className="hvs" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{sensitivity10}</p>
      </section>
      </section>
     {/* Antibiotics plus Sensitivity */}

     </div>
    </div>
    </div>


    <div className="buttonflex">
    <div >
      {/* Our lovely button */}
      <button
      type='button'
      className='btn-pri'
      ><EditReportHvs id={id} dmcsCTitles={dmcsCTitles} date={date} dmcsCSubheading={dmcsCSubheading} dmicroscopyHeader={dmicroscopyHeader} dmicroscopyCells01={dmicroscopyCells01} dmicroscopyCells02={dmicroscopyCells02} dmicroscopyCells03={dmicroscopyCells03} dmicroscopyCells04={dmicroscopyCells04} dmicroscopyValues01={dmicroscopyValues01} dmicroscopyValues02={dmicroscopyValues02} dmicroscopyValues03={dmicroscopyValues03} dmicroscopyValues04={dmicroscopyValues04} dcultureCTitle={dcultureCTitle} dcultureCSubHeading={dcultureCSubHeading} dcultureC1Header={dcultureC1Header} dcultureC2Header={dcultureC2Header} antibiotics01={antibiotics01}  sensitivity01={sensitivity01} antibiotics02={antibiotics02} sensitivity02={sensitivity02} antibiotics03={antibiotics03} sensitivity03={sensitivity03} antibiotics04={antibiotics04} sensitivity04={sensitivity04} antibiotics05={antibiotics05} sensitivity05={sensitivity05} antibiotics06={antibiotics06} sensitivity06={sensitivity06} antibiotics07={antibiotics07} sensitivity07={sensitivity07} antibiotics08={antibiotics08} sensitivity08={sensitivity08} antibiotics09={antibiotics09} sensitivity09={sensitivity09} antibiotics10={antibiotics10} sensitivity10={sensitivity10} checkedby={checkedby} /></button> 
      </div>

      {/* Our lovely button */}
    <div>
      <Link to={`/users/${userId}/reports/hvs/${id}`}>
      <button
      type='button'
      className='btn-prime'
      >
        <PreviewIcon/>
      </button>
    </Link>
      </div>
    </div>

    </>
)}
     {/* Mapped Area */}
    
      {/* Ending Div */}
        </div>


 {/* Modal */}
 <div className="modal fade" id="totalmcsModal" tabindex="-1" aria-labelledby="totalmcsModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl modal-dialog-scrollable">
  <form className="d-flex" onSubmit={submitTask}>
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="totalmcsModalLabel">M/C/S</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">

  <form>

  <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">M/C/S TITLE</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter M/C/S Report Title"
      list="mcsCTitles"
      onChange={(e) => setMcsTitle(e.target.value)}
    /> 
    <datalist id="mcsCTitles">
    {mcsCTitles.map((op)=><option>{op}</option>)}
    </datalist>
    </div>    

    <input
      type="date"
      className="form-control"
      onChange={(e) => setCreateDate(e.target.value)}
    /> 

  <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">MICROBIOLOGY SUB HEADING</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Microbiology Sub Heading"
      list="mcsCSubheading"
      onChange={(e) => setMcsSubheading(e.target.value)}
    /> 
    <datalist id="mcsCSubheading">
    {mcsCSubheading.map((op)=><option>{op}</option>)}
    </datalist>
    </div>       


  <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">MICROSCOPY HEADING</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Microscopy Heading"
      list="microscopyHeader"
      onChange={(e) => setMicroscopyHeader(e.target.value)}
    /> 
    <datalist id="microscopyHeader">
    {microscopyHeader.map((op)=><option>{op}</option>)}
    </datalist>
    </div>     

   
     <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-1">Microscopy Row1AData and Row1BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row1AData"
      list="microscopyCells"
      onChange={(e) => setMicroscopyCell01(e.target.value)}
    />
     <datalist id="microscopyCells">
    {microscopyCells.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row1BData"
      list="microscopyValues"
      onChange={(e) => setMicroscopyValue01(e.target.value)}
    />
     <datalist id="microscopyValues">
    {microscopyValues.map((op)=><option>{op}</option>)}
    </datalist>
    </div>      


     <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-2">Microscopy Row2AData and Row2BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row2AData"
      list="microscopyCells"
      onChange={(e) => setMicroscopyCell02(e.target.value)}
    />
     <datalist id="microscopyCells">
    {microscopyCells.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row2BData"
      list="microscopyValues"
      onChange={(e) => setMicroscopyValue02(e.target.value)}
    />
     <datalist id="microscopyValues">
    {microscopyValues.map((op)=><option>{op}</option>)}
    </datalist>
    </div>    


     <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-3">Microscopy Row3AData and Row3BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row3AData"
      list="microscopyCells"
      onChange={(e) => setMicroscopyCell03(e.target.value)}
    />
     <datalist id="microscopyCells">
    {microscopyCells.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row3BData"
      list="microscopyValues"
      onChange={(e) => setMicroscopyValue03(e.target.value)}
    />
     <datalist id="microscopyValues">
    {microscopyValues.map((op)=><option>{op}</option>)}
    </datalist>
    </div>      


     <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-4">Microscopy Row4AData and Row4BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row4AData"
      list="microscopyCells"
      onChange={(e) => setMicroscopyCell04(e.target.value)}
    />
     <datalist id="microscopyCells">
    {microscopyCells.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row4BData"
      list="microscopyValues"
      onChange={(e) => setMicroscopyValue04(e.target.value)}
    />
     <datalist id="microscopyValues">
    {microscopyValues.map((op)=><option>{op}</option>)}
    </datalist>
    </div>      

    
  <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">CULTUE HEADING</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Culture Heading"
      list="cultureCTitle"
      onChange={(e) => setCultureTitle(e.target.value)}
    /> 
    <datalist id="cultureCTitle">
    {cultureCTitle.map((op)=><option>{op}</option>)}
    </datalist>
    </div>  


  <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">CULTUE SUBHEADING</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Culture SubHeading"
      list="cultureCSubHeading"
      onChange={(e) => setCultureSubHeading(e.target.value)}
    /> 
    <datalist id="cultureCSubHeading">
    {cultureCSubHeading.map((op)=><option>{op}</option>)}
    </datalist>
    </div>  

     <div class="input-group">
     <div class="input-group-prepend">
    <span class="input-group-text" id="pair-1">ANTIBIOTICS AND SENSITIVITY ROW HEADER</span>
  </div>
  <input
      type="text"
      className="form-control"
      list="cultureC1Header"
      placeholder="Enter Row1HeaderTitle"
      onChange={(e) => setCulture1Header(e.target.value)}
    /> 
    <datalist id="cultureC1Header">
    {cultureC1Header.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row2HeaderTitle"
      list="cultureC2Header"
      onChange={(e) => setCulture2Header(e.target.value)}
    />
     <datalist id="cultureC2Header">
    {cultureC2Header.map((op)=><option>{op}</option>)}
    </datalist>
    </div>

    
    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-01">Culture Row1AData and Row1BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row1AData"
      list="crow1listings"
      onChange={(e) => setCreateAntibiotics01(e.target.value)}
    />
     <datalist id="crow1listings">
    {crow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row1BData"
      list="crow2listings"
      onChange={(e) => setCreateSensitivity01(e.target.value)}
    />
     <datalist id="crow2listings">
    {crow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-02">Culture Row2AData and Row2BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row2AData"
      list="crow1listings"
      onChange={(e) => setCreateAntibiotics02(e.target.value)}
    />
     <datalist id="crow1listings">
    {crow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row2BData"
      list="crow2listings"
      onChange={(e) => setCreateSensitivity02(e.target.value)}
    />
     <datalist id="crow2listings">
    {crow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-03">Culture Row3AData and Row3BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row3AData"
      list="crow1listings"
      onChange={(e) => setCreateAntibiotics03(e.target.value)}
    />
     <datalist id="crow1listings">
    {crow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row3BData"
      list="crow2listings"
      onChange={(e) => setCreateSensitivity03(e.target.value)}
    />
     <datalist id="crow2listings">
    {crow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-04">Culture Row4AData and Row4BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row4AData"
      list="crow1listings"
      onChange={(e) => setCreateAntibiotics04(e.target.value)}
    />
     <datalist id="crow1listings">
    {crow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row4BData"
      list="crow2listings"
      onChange={(e) => setCreateSensitivity04(e.target.value)}
    />
     <datalist id="crow2listings">
    {crow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-05">Culture Row5AData and Row5BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row5AData"
      list="crow1listings"
      onChange={(e) => setCreateAntibiotics05(e.target.value)}
    />
     <datalist id="crow1listings">
    {crow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row5BData"
      list="crow2listings"
      onChange={(e) => setCreateSensitivity05(e.target.value)}
    />
     <datalist id="crow2listings">
    {crow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-06">Culture Row6AData and Row6BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row6AData"
      list="crow1listings"
      onChange={(e) => setCreateAntibiotics06(e.target.value)}
    />
     <datalist id="crow1listings">
    {crow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row6BData"
      list="crow2listings"
      onChange={(e) => setCreateSensitivity06(e.target.value)}
    />
     <datalist id="crow2listings">
    {crow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-07">Culture Row7AData and Row7BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row7AData"
      list="crow1listings"
      onChange={(e) => setCreateAntibiotics07(e.target.value)}
    />
     <datalist id="crow1listings">
    {crow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row7BData"
      list="crow2listings"
      onChange={(e) => setCreateSensitivity07(e.target.value)}
    />
     <datalist id="crow2listings">
    {crow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-08">Culture Row8AData and Row8BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row8AData"
      list="crow1listings"
      onChange={(e) => setCreateAntibiotics08(e.target.value)}
    />
     <datalist id="crow1listings">
    {crow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row8BData"
      list="crow2listings"
      onChange={(e) => setCreateSensitivity08(e.target.value)}
    />
     <datalist id="crow2listings">
    {crow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-09">Culture Row9AData and Row9BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row9AData"
      list="crow1listings"
      onChange={(e) => setCreateAntibiotics09(e.target.value)}
    />
     <datalist id="crow1listings">
    {crow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row9BData"
      list="crow2listings"
      onChange={(e) => setCreateSensitivity09(e.target.value)}
    />
     <datalist id="crow2listings">
    {crow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-10">Culture Row10AData and Row10BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row10AData"
      list="crow1listings"
      onChange={(e) => setCreateAntibiotics10(e.target.value)}
    />
     <datalist id="crow1listings">
    {crow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row10BData"
      list="crow2listings"
      onChange={(e) => setCreateSensitivity10(e.target.value)}
    />
     <datalist id="crow2listings">
    {crow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 

    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">CHECKED BY</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Checked by"
      onChange={(e) => setCheckedby(e.target.value)}
    /> 
    </div>  
  
    </form>

    
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary">Add Data</button>
      </div>
    </div>
    </form>
  </div>
</div>


        </>
  )
}

export default ReportHvs