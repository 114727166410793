import React, { useState } from 'react'
import { doc, updateDoc } from 'firebase/firestore'
import {db} from "../../firebase"
import 'bootstrap/dist/css/bootstrap.min.css';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { useParams } from 'react-router-dom';


const EditObstetrics = ({id, children}) => {

    const [createChildren, setCreateChildren] = useState(children);

    const {userId} = useParams();

    const updateTask = async (e) => {
        e.preventDefault()
        try {
          const taskDocument = doc(db, "androcare/" + userId , "obstetrics", id);
          await updateDoc(taskDocument, {
           children: createChildren
          });
          window.location.reload();
        } catch (err) {
          console.log(err);
        }
      }

  return (
    <>
    <button
      type="button"
      className="btn btn-primary btn-sm"
      data-bs-toggle="modal"
      data-bs-target={`#id${id}`}>
      <EditNoteRoundedIcon/>
    </button>

    <div
      className="modal fade"
      id={`id${id}`}
      tabIndex="-1"
      aria-labelledby="editLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editLabel" style={{color:"green"}}>
              Update Obstetrics History</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">

              <input
                type="text"
                className="form-control"
                defaultValue={createChildren}
                onChange={e => setCreateChildren(e.target.value)}
              />

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={e => updateTask(e)}
            >Update History</button>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default EditObstetrics