import "./gynaecological.scss";
import {useEffect, useState} from 'react'
import {db} from "../../firebase"
import { addDoc, collection, deleteDoc, doc, getDocs, query, runTransaction, serverTimestamp } from 'firebase/firestore'
import EditGynaecological from "./EditGynaecological";
import { useParams } from "react-router-dom";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

const Gynaecological = () => {

  const [tasks, setTasks] = useState([]);
  const [createAge, setCreateAge] = useState("")
  const [createLmp, setCreateLmp] = useState("")
  const [createCycle, setCreateCycle] = useState("")
  const [createColor, setCreateColor] = useState("")
  const [createRegularity, setCreateRegularity] = useState("")
  const [createHair, setCreateHair] = useState("")
  const [createHormonal, setCreateHormonal] = useState("")
  const [createFertility, setCreateFertility] = useState("")
  const [createHsg, setCreateHsg] = useState("")
  const [checked, setChecked] = useState([]);

  const {userId} = useParams();

  const collectionRef = collection(db, "androcare/" + userId , "gynaecology")

  useEffect(()=>{
    const getTasks = async () => {
      const q = query(collectionRef)
      await getDocs(q).then((list)=>{
       let tasksData = list.docs.map((doc) => ({...doc.data(),
      id: doc.id}))
      setTasks(tasksData)
      setChecked(tasksData)
      }).catch((err) =>{
        console.log(err)
      })
    }
    getTasks()
  }, [])


    //Add Task Handler
 const submitTask = async (e) => {
  e.preventDefault();
  try {
    await addDoc(collectionRef, {
      age: createAge,
      lmp: createLmp,
      cycle: createCycle,
      color: createColor,
      regularity: createRegularity,
      hair: createHair,
      hormonal: createHormonal,
      fertility: createFertility,
      hsgResult : createHsg,
      isYes: false,
      isNo: false,
      isHeavy: false,
      isModerate: false,
      isLow: false,
      isBYes: false,
      isBNo: false,
      isCaesarian: false,
      isMyomectomy: false,
      isAppendectomy: false,
      isOvarian: false,
      isD: false,
      isNone: false,
      timeStamp: serverTimestamp()
    })
    window.location.reload();
  } catch (err) {
    console.log(err);
  }
}


     //Delete Handler
    //  const deleteTask = async (id) => {
    //   try {
    //     window.confirm("Are you sure you want to delete this task?")
    //     const documentRef = doc(db, "androcare/" + userId , "gynaecology", id);
    //     await deleteDoc(documentRef)
    //     window.location.reload();
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }

// Yes
const checkBoxYes = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isYes: !state[indexToUpdate].isYes,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isYes;
    transaction.update(docRef, { isYes: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// No
const checkBoxNo = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isNo: !state[indexToUpdate].isNo,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isNo;
    transaction.update(docRef, { isNo: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Heavy
const checkBoxHeavy = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isHeavy: !state[indexToUpdate].isHeavy,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isHeavy;
    transaction.update(docRef, { isHeavy: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Moderate
const checkBoxModerate = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isModerate: !state[indexToUpdate].isModerate,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isModerate;
    transaction.update(docRef, { isModerate: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Low
const checkBoxLow = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isLow: !state[indexToUpdate].isLow,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isLow;
    transaction.update(docRef, { isLow: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// BYes
const checkBoxBYes = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isBYes: !state[indexToUpdate].isBYes,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isBYes;
    transaction.update(docRef, { isBYes: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// BNo
const checkBoxBNo = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isBNo: !state[indexToUpdate].isBNo,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isBNo;
    transaction.update(docRef, { isBNo: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Caesarian
const checkBoxCaesarian = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isCaesarian: !state[indexToUpdate].isCaesarian,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isCaesarian;
    transaction.update(docRef, { isCaesarian: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Myomectomy
const checkBoxMyomectomy = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isMyomectomy: !state[indexToUpdate].isMyomectomy,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isMyomectomy;
    transaction.update(docRef, { isMyomectomy: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Appendectomy
const checkBoxAppendectomy = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isAppendectomy: !state[indexToUpdate].isAppendectomy,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isAppendectomy;
    transaction.update(docRef, { isAppendectomy: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// Ovarian
const checkBoxOvarian = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isOvarian: !state[indexToUpdate].isOvarian,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isOvarian;
    transaction.update(docRef, { isOvarian: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// D
const checkBoxD = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isD: !state[indexToUpdate].isD,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isD;
    transaction.update(docRef, { isD: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// None
const checkBoxNone = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isNone: !state[indexToUpdate].isNone,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "gynaecology" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isNone;
    transaction.update(docRef, { isNone: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}




  return (
    <>
    <div className="gynae">
      Gynaecological History
      <hr />

  {/* Modal Button */}
  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#gynaecologyModal">
    Add History
  </button>


      {/* Mapped Area */}
      {tasks.map(({id, age, lmp, cycle, color, regularity, hair, hormonal, hsgResult, fertility, isYes, isNo, isHeavy, isModerate, isLow, isBYes, isBNo, isCaesarian, isMyomectomy, isAppendectomy, isOvarian, isD, isNone})=>
      <>
      <div className="gynaehistory"  key={id}>
        <div className="gynaedata">
        <div className="gynaedata1">
        <span>
        <p style={{fontSize:18, color:"#ca4200"}}><strong>Age of Menarche:&nbsp;</strong> {age} </p>
        <p style={{fontSize:18, color:"#ca4200"}}><strong>L.M.P:&nbsp;</strong> {lmp} </p>
        <p style={{fontSize:18, color:"#ca4200"}}><strong>Cycle length:&nbsp;</strong> {cycle} </p>

        <span className="pim">
        <p><strong>Pain in Menstruation:&nbsp;</strong></p>
        <input type="checkbox" 
          defaultChecked={isYes}
          onChange={(e) => checkBoxYes(e)}  
          name={id}
        /> <p>Yes</p> 
        <input type="checkbox" 
          defaultChecked={isNo}
          onChange={(e) => checkBoxNo(e)}  
          name={id}
        /> <p>No</p> 
        </span>
        
        <span className="pim2">
        <p><strong>Volume of Period:&nbsp;</strong></p>
        <input type="checkbox" 
          defaultChecked={isHeavy}
          onChange={(e) => checkBoxHeavy(e)}  
          name={id}
        /> <p>Heavy</p> 
        <input type="checkbox" 
          defaultChecked={isModerate}
          onChange={(e) => checkBoxModerate(e)}  
          name={id}
        /> <p>Moderate</p> 
        <input type="checkbox" 
          defaultChecked={isLow}
          onChange={(e) => checkBoxLow(e)}  
          name={id}
        /> <p>Low</p> 
        </span>
        
        <p style={{fontSize:18, color:"#ca4200"}}><strong>Menstrual color:&nbsp;</strong> {color} </p>
        <p style={{fontSize:18, color:"#ca4200"}}><strong>Menstrual Regularity:&nbsp;</strong> {regularity} </p>
        <span className="pim">
        <p><strong>Breast Discharge:&nbsp;</strong></p>
        <input type="checkbox" 
          defaultChecked={isBYes}
          onChange={(e) => checkBoxBYes(e)}  
          name={id}
        /> <p>Yes</p> 
        <input type="checkbox" 
          defaultChecked={isBNo}
          onChange={(e) => checkBoxBNo(e)}  
          name={id}
        /> <p>No</p> 
        </span>

          </span>
        </div>
        </div>


        <div className="gynaeboxes">
          <div className="gynaedata2">
            <span>
            <p style={{fontSize:18, color:"#ca4200"}}><strong>Hair growth &#40;Chin/Chest&#41;:&nbsp;</strong> {hair} </p>
            <p style={{fontSize:18, color:"#ca4200"}}><strong>Previous Hormonal Assay:&nbsp;</strong> {hormonal} </p>
            <p><strong>Previous HSG Result:&nbsp;</strong>&nbsp;{hsgResult}</p>

            <p><strong>Previous Surgery&nbsp;</strong></p>
        

            <span className="pim3">
        <input type="checkbox" 
          defaultChecked={isCaesarian}
          onChange={(e) => checkBoxCaesarian(e)}  
          name={id}
        /> <p>Caesarian Section</p> 
        <input type="checkbox"
          defaultChecked={isMyomectomy}
          onChange={(e) => checkBoxMyomectomy(e)}  
          name={id}
        /> <p>Myomectomy</p> 
        <input type="checkbox"
          defaultChecked={isAppendectomy}
          onChange={(e) => checkBoxAppendectomy(e)}  
          name={id}
        /> <p>Appendectomy</p> 
        </span>

        <span className="pim4">
        <input type="checkbox" 
          defaultChecked={isOvarian}
          onChange={(e) => checkBoxOvarian(e)}  
          name={id}
        /> <p>Ovarian cyst removal</p> 
        <input type="checkbox" 
          defaultChecked={isD}
          onChange={(e) => checkBoxD(e)}  
          name={id}
        /> <p>D/C</p> 
        <input type="checkbox" 
          defaultChecked={isNone}
          onChange={(e) => checkBoxNone(e)}  
          name={id}
        /> <p>None</p> 
        </span>

        <p style={{fontSize:18, color:"#ca4200"}}><strong>Previous fertility treatment:&nbsp;</strong> {fertility} </p>
            </span>
          </div>
        </div>
      </div>

      
      <div class="col-md-12 text-center">
      {/* Our lovely button */}
      <button
      type='button'
      className='btn-pri'
      ><EditGynaecological id={id} age={age} lmp={lmp} cycle={cycle} color={color} regularity={regularity} hair={hair} hormonal={hormonal} fertility={fertility} hsgResult={hsgResult}/></button>

      {/* <button
        type="button"
         className="btn btn-danger"
         onClick={() => deleteTask(id)}
       ><DeleteSweepIcon/></button> */}
        {/* Mapped Area */}
      </div>
      </>
)}
    </div>

       {/* Modal */}
       <div className="modal fade" id="gynaecologyModal" tabindex="-1" aria-labelledby="gynaecologyModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-scrollable">
  <form className="d-flex" onSubmit={submitTask}>
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="gynaecologyModalLabel">Gynaecological History</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">

      <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-01">AGE OF MENARCHE</span>
  </div>
    <input
      type="text"
      className="form-control"
      placeholder="Enter age of menarche"
      onChange={(e) => setCreateAge(e.target.value)}
    />
     </div>  


     <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-02">L.M.P</span>
  </div>
    <input
      type="text"
      className="form-control"
      placeholder="L.M.P"
      onChange={(e) => setCreateLmp(e.target.value)}
    />
    </div>


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-03">CYCLE LENGTH</span>
  </div>
    <input
      type="text"
      className="form-control"
      placeholder="Cycle length"
      onChange={(e) => setCreateCycle(e.target.value)}
    />
    </div>


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-04">MENSTRUAL COLOR</span>
  </div>
    <input
      type="text"
      className="form-control"
      placeholder="Enter menstrual color"
      onChange={(e) => setCreateColor(e.target.value)}
    />
    </div>


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-05">MENSTRUAL REGULARITY</span>
  </div>
    <input
      type="text"
      className="form-control"
      placeholder="Enter menstrual regularity"
      onChange={(e) => setCreateRegularity(e.target.value)}
    />
    </div>


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-06">HAIR GROWTH</span>
  </div>
    <input
      type="text"
      className="form-control"
      placeholder="Enter hair growth(chin/chest)"
      onChange={(e) => setCreateHair(e.target.value)}
    />
    </div>


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-07">PREVIOUS HORMONAL ASSAY</span>
  </div>
    <input
      type="text"
      className="form-control"
      placeholder="Previous hormonal assay"
      onChange={(e) => setCreateHormonal(e.target.value)}
    />
    </div>


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-07">PREVIOUS HSG RESULT</span>
  </div>
    <input
      type="text"
      className="form-control"
      placeholder="Previous Hsg Result"
      onChange={(e) => setCreateHsg(e.target.value)}
    />
    </div>

  
    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-08">PREVIOUS FERT TREATMENT</span>
  </div>
    <input
      type="text"
      className="form-control"
      placeholder="Previous fertility treatment"
      onChange={(e) => setCreateFertility(e.target.value)}
    />
    </div>

    
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary">Add Gynaecology</button>
      </div>
    </div>
    </form>
  </div>
</div>

    </>
  )
}

export default Gynaecological;