import "./reportlftb.scss";
import { addDoc, collection, getDocs, query, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import EditReportLftb from "./EditReportLftb";
import PreviewIcon from '@mui/icons-material/Preview';


const ReportLftB = () => {

  const lftbTitle = ["BILIRUBIN REPORT", "E/U/CR REPORT", "LIPID PROFILE", "LIVER FUNTION TEST REPORT", "FASTING BLOOD GLUCOSE REPORT"]

  const lftbSubHeader = ["BIOCHEMISTRY", "CHEMISTRY"]

  const lftb1Header = [ "INVESTIGATION"]

  const lftb2Header = ["RESULT"]

  const lftb3Header = ["REFERENCE", "NORMAL VALUES", "RANGE mg/dl"]

  const lftb1listings = ["TOTAL BILIRUBIN", "DIRECT BILIRUBIN","ALK.PHOSPHATASE", "ALT", "AST", "TOTAL PROTEIN", "ALBUMIN", "Sodium:", "Potassium:", "Chloride:", "Bicarbonate:", "Urea:", "Creatinine:", "TOTAL CHOLESTEROL:", "TRIGLYCERIDES:", "HDL:", "LDL:", "FASTING BLOOD GLUCOSE"]

  const lftb2listings = ["34mg/dl", "4.2mg/dl", "142.1mmol/L", "3.2mmol/L", "81 mmol/L", "25.1mmol/L", "34 mg/dl", "1.0mg/dl", "160mg/dl", "130md/dl", "54mg/dl", "80mg/dl","0.5","0.17","85","30","47","7.4","4.5"]

  const lftb3listings = ["(0.1-1.1)mg/dl", "(77-120)mg/dl", "(0.1 - 0.25)mg/dl", "133-150", "3.5-5.1", "98-107", "21-30", "10-50", "0.5-1.1", "(100-200)mg/dl", "(50-150)mg/dl", "(40-60)mg/dl", "(20-150)mg/dl", "(0.1-1.1)mg/dl", "(0.1 - 0.25)mg/dl", "(40 - 150)u/l", "(< 34.0 )u/l", "(< 31.0 )u/l", "(6.1 - 8.0)g/dl", "(3.5 - 5.5 )g/dl"]


  const [tasks, setTasks] = useState([]);
  const [createTitle, setCreateTitle] = useState("")
  const [createRow1Header, setCreateRow1Header] = useState("")
  const [createRow2Header, setCreateRow2Header] = useState("")
  const [createRow3Header, setCreateRow3Header] = useState("")
  const [createSubHeader, setCreateSubHeader] = useState("")
  const [createDate, setCreateDate] = useState("")
  const [createInvestigation01, setCreateInvestigation01] = useState("")
  const [createResult01, setCreateResult01] = useState("")
  const [createReference01, setCreateReference01] = useState("")
  const [createInvestigation02, setCreateInvestigation02] = useState("")
  const [createResult02, setCreateResult02] = useState("")
  const [createReference02, setCreateReference02] = useState("")
  const [createInvestigation03, setCreateInvestigation03] = useState("")
  const [createResult03, setCreateResult03] = useState("")
  const [createReference03, setCreateReference03] = useState("")
  const [createInvestigation04, setCreateInvestigation04] = useState("")
  const [createResult04, setCreateResult04] = useState("")
  const [createReference04, setCreateReference04] = useState("")
  const [createInvestigation05, setCreateInvestigation05] = useState("")
  const [createResult05, setCreateResult05] = useState("")
  const [createReference05, setCreateReference05] = useState("")
  const [createInvestigation06, setCreateInvestigation06] = useState("")
  const [createResult06, setCreateResult06] = useState("")
  const [createReference06, setCreateReference06] = useState("")
  const [createInvestigation07, setCreateInvestigation07] = useState("")
  const [createResult07, setCreateResult07] = useState("")
  const [createReference07, setCreateReference07] = useState("")
  const [createInvestigation08, setCreateInvestigation08] = useState("")
  const [createResult08, setCreateResult08] = useState("")
  const [createReference08, setCreateReference08] = useState("")
  const [createInvestigation09, setCreateInvestigation09] = useState("")
  const [createResult09, setCreateResult09] = useState("")
  const [createReference09, setCreateReference09] = useState("")
  const [createInvestigation10, setCreateInvestigation10] = useState("")
  const [createResult10, setCreateResult10] = useState("")
  const [createReference10, setCreateReference10] = useState("")


  const {userId} = useParams();
  
  const collectionRef = collection(db, "androcare/" + userId , "totalLftb")

  useEffect(()=>{ 
    const getTasks = async () => {
      const q = query(collectionRef)
      await getDocs(q).then((task)=>{
       let tasksData = task.docs.map((doc) => ({...doc.data(),
      id: doc.id}))
      setTasks(tasksData)
      }).catch((err) =>{
        console.log(err)
      })
    }
    getTasks()
  }, [])

    
     // Add Task Handler
     const submitTask = async (e) => {
      e.preventDefault();  
      try {
        await addDoc(collectionRef, {
          reportTitle : createTitle,
          date : createDate,
          row1Header : createRow1Header,
          row2Header : createRow2Header,
          row3Header : createRow3Header,
          subHeader : createSubHeader,
          investigation01 : createInvestigation01,
          result01 : createResult01,
          reference01 : createReference01,
          investigation02 : createInvestigation02,
          result02 : createResult02,
          reference02 : createReference02,
          investigation03 : createInvestigation03,
          result03 : createResult03,
          reference03 : createReference03,
          investigation04 : createInvestigation04,
          result04 : createResult04,
          reference04 : createReference04,
          investigation05 : createInvestigation05,
          result05 : createResult05,
          reference05 : createReference05,
          investigation06 : createInvestigation06,
          result06 : createResult06,
          reference06 : createReference06,
          investigation07 : createInvestigation07,
          result07 : createResult07,
          reference07 : createReference07,
          investigation08 : createInvestigation08,
          result08 : createResult08,
          reference08 : createReference08,
          investigation09 : createInvestigation09,
          result09 : createResult09,
          reference09 : createReference09,
          investigation10 : createInvestigation10,
          result10 : createResult10,
          reference10 : createReference10,
          timeStamp: serverTimestamp()
        })
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }

  return (
    <>
    <div className="reportlftb">

    <p>LIVER FUNCTION TEST, BILIRUBIN, LIPID PROFILE, FASTING BLOOD GLUCOSE AND E/U/CR</p>

    <hr />

    {/* Modal Button */}
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#lftbModal">
    Add Test
    </button>  

    
    {tasks.map(({id,reportTitle,date,row1Header,row2Header,row3Header,subHeader,investigation01,result01,reference01,investigation02,result02,reference02,investigation03,result03,reference03,investigation04,result04,reference04,investigation05,result05,reference05,investigation06,result06,reference06,investigation07,result07,reference07,investigation08,result08,reference08,investigation09,result09,reference09,investigation10,result10,reference10})=>
    <>
    <div className="coveragelftb">
    <div className="overalllftb" key={id}>
    <div className="headerlftb">
          <p>{row1Header}</p>
          <p>{row2Header}</p>  
          <p>{row3Header}</p>  
    </div>
    <div className="contentlftb">

      {/* Investigation vis result */}
      <section style={{marginTop: "8px"}}>
      <section className="lftb" style={{marginLeft : "18px"}}>
      <p>{investigation01}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{result01}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "400px"}}>
      <p>{reference01}</p>
      </section>
      </section>
     {/* Investigation vis result */}

      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="lftb" style={{marginLeft : "18px"}}>
      <p>{investigation02}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{result02}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "400px"}}>
      <p>{reference02}</p>
      </section>
      </section>
     {/* Investigation vis result */}
    
       {/* Investigation vis result */}
       <section style={{marginTop: "-38px"}}>
      <section className="lftb" style={{marginLeft : "18px"}}>
      <p>{investigation03}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{result03}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "400px"}}>
      <p>{reference03}</p>
      </section>
      </section>
     {/* Investigation vis result */}

      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="lftb" style={{marginLeft : "18px"}}>
      <p>{investigation04}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{result04}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "400px"}}>
      <p>{reference04}</p>
      </section>
      </section>
     {/* Investigation vis result */}

      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="lftb" style={{marginLeft : "18px"}}>
      <p>{investigation05}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{result05}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "400px"}}>
      <p>{reference05}</p>
      </section>
      </section>
     {/* Investigation vis result */}

      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="lftb" style={{marginLeft : "18px"}}>
      <p>{investigation06}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{result06}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "400px"}}>
      <p>{reference06}</p>
      </section>
      </section>
     {/* Investigation vis result */}

      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="lftb" style={{marginLeft : "18px"}}>
      <p>{investigation07}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{result07}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "400px"}}>
      <p>{reference07}</p>
      </section>
      </section>
     {/* Investigation vis result */}

      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="lftb" style={{marginLeft : "18px"}}>
      <p>{investigation08}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{result08}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "400px"}}>
      <p>{reference08}</p>
      </section>
      </section>
     {/* Investigation vis result */}


      {/* Investigation vis result */}
      <section style={{marginTop: "-38px"}}>
      <section className="lftb" style={{marginLeft : "18px"}}>
      <p>{investigation09}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{result09}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "400px"}}>
      <p>{reference09}</p>
      </section>
      </section>
     {/* Investigation vis result */}


      {/* Investigation vis result */}
      <section style={{marginTop: "8px"}}>
      <section className="lftb" style={{marginLeft : "18px"}}>
      <p>{investigation10}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "250px"}}>
      <p>{result10}</p>
      </section>
      <section className="lftb" style={{marginTop: "-41.0px", marginLeft: "400px"}}>
      <p>{reference10}</p>
      </section>
      </section>
     {/* Investigation vis result */}


     </div>
    </div>
    </div>

     <div className="buttonflex">
    <div >
      {/* Our lovely button */}
      <button
      type='button'
      className='btn-pri'
      ><EditReportLftb id={id} reportTitle={reportTitle} date={date} row1Header={row1Header} row2Header={row2Header} row3Header={row3Header} subHeader={subHeader} investigation01={investigation01} result01={result01} reference01={reference01} investigation02={investigation02} result02={result02} reference02={reference02} investigation03={investigation03} result03={result03} reference03={reference03} investigation04={investigation04} result04={result04} reference04={reference04} investigation05={investigation05} result05={result05} reference05={reference05} investigation06={investigation06} result06={result06} reference06={reference06} investigation07={investigation07} result07={result07} reference07={reference07} investigation08={investigation08} result08={result08} reference08={reference08} investigation09={reference09} result09={result09} reference09={reference09} investigation10={investigation10} result10={result10} reference10={reference10} /></button> 
      </div>

      {/* Our lovely button */}
    <div>
      <Link to={`/users/${userId}/reports/lftb/${id}`}>
      <button
      type='button'
      className='btn-prime'
      >
        <PreviewIcon/>
      </button>
    </Link>
      </div>
    </div>
    </>
)}    

    {/* Ending Div */}
    </div>

    
              {/* Modal */}
              <div className="modal fade" id="lftbModal" tabindex="-1" aria-labelledby="lftbModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-xl modal-dialog-scrollable">
              <form className="d-flex" onSubmit={submitTask}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="lftbModalLabel">FULL BLOOD COUNT</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
            
              <form>
            
              <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair">REPORT TITLE</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Report Title"
                  list="lftbTitle"
                  onChange={(e) => setCreateTitle(e.target.value)}
                /> 
                <datalist id="lftbTitle">
                {lftbTitle.map((op)=><option>{op}</option>)}
                </datalist>
                </div>              
                     
                <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair">SUB HEADING</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter RBC Sub Heading"
                  list="lftbSubHeader"
                  onChange={(e) => setCreateSubHeader(e.target.value)}
                /> 
                <datalist id="lftbSubHeader">
                {lftbSubHeader.map((op)=><option>{op}</option>)}
                </datalist>
                </div> 
            
              <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setCreateDate(e.target.value)}
                /> 
            
            
              <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair-1">Row1HeaderTitle, Row2HeaderTitle and Row3HeaderTitle</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  list="lftb1Header"
                  placeholder="Enter Row1HeaderTitle"
                  onChange={(e) => setCreateRow1Header(e.target.value)}
                /> 
                <datalist id="lftb1Header">
                {lftb1Header.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row2HeaderTitle"
                  list="lftb2Header"
                  onChange={(e) => setCreateRow2Header(e.target.value)}
                />
                 <datalist id="lftb2Header">
                {lftb2Header.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row3HeaderTitle"
                  list="lftb3Header"
                  onChange={(e) => setCreateRow3Header(e.target.value)}
                />
                 <datalist id="lftb3Header">
                {lftb3Header.map((op)=><option>{op}</option>)}
                </datalist>
                </div>
            
            
              <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair-1">Row1AData, Row1BData and Row1CData</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row1AData"
                  list="lftb1listings"
                  onChange={(e) => setCreateInvestigation01(e.target.value)}
                /> 
                 <datalist id="lftb1listings">
                {lftb1listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row1BData"
                  list="lftb2listings"
                  onChange={(e) => setCreateResult01(e.target.value)}
                />
                  <datalist id="lftb2listings">
                {lftb2listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row1CData"
                  list="lftb3listings"
                  onChange={(e) => setCreateReference01(e.target.value)}
                />
                  <datalist id="lftb3listings">
                {lftb3listings.map((op)=><option>{op}</option>)}
                </datalist>
                </div>
         
         
              <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair-2">Row2AData, Row2BData and Row2CData</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row2AData"
                  list="lftb1listings"
                  onChange={(e) => setCreateInvestigation02(e.target.value)}
                /> 
                 <datalist id="lftb1listings">
                {lftb1listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row2BData"
                  list="lftb2listings"
                  onChange={(e) => setCreateResult02(e.target.value)}
                />
                  <datalist id="lftb2listings">
                {lftb2listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row2CData"
                  list="lftb3listings"
                  onChange={(e) => setCreateReference02(e.target.value)}
                />
                  <datalist id="lftb3listings">
                {lftb3listings.map((op)=><option>{op}</option>)}
                </datalist>
                </div>
        
         
                <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair-3">Row3AData, Row3BData and Row3CData</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row3AData"
                  list="lftb1listings"
                  onChange={(e) => setCreateInvestigation03(e.target.value)}
                /> 
                 <datalist id="lftb1listings">
                {lftb1listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row3BData"
                  list="lftb2listings"
                  onChange={(e) => setCreateResult03(e.target.value)}
                />
                  <datalist id="lftb2listings">
                {lftb2listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row3CData"
                  list="lftb3listings"
                  onChange={(e) => setCreateReference03(e.target.value)}
                />
                  <datalist id="lftb3listings">
                {lftb3listings.map((op)=><option>{op}</option>)}
                </datalist>
                </div>
         
         
                <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair-4">Row4AData, Row4BData and Row4CData</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row4AData"
                  list="lftb1listings"
                  onChange={(e) => setCreateInvestigation04(e.target.value)}
                /> 
                 <datalist id="lftb1listings">
                {lftb1listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row4BData"
                  list="lftb2listings"
                  onChange={(e) => setCreateResult04(e.target.value)}
                />
                  <datalist id="lftb2listings">
                {lftb2listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row4CData"
                  list="lftb3listings"
                  onChange={(e) => setCreateReference04(e.target.value)}
                />
                  <datalist id="lftb3listings">
                {lftb3listings.map((op)=><option>{op}</option>)}
                </datalist>
                </div>
              
         
                <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair-5">Row5AData, Row5BData and Row5CData</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row5AData"
                  list="lftb1listings"
                  onChange={(e) => setCreateInvestigation05(e.target.value)}
                /> 
                 <datalist id="lftb1listings">
                {lftb1listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row5BData"
                  list="lftb2listings"
                  onChange={(e) => setCreateResult05(e.target.value)}
                />
                  <datalist id="lftb2listings">
                {lftb2listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row5CData"
                  list="lftb3listings"
                  onChange={(e) => setCreateReference05(e.target.value)}
                />
                  <datalist id="lftb3listings">
                {lftb3listings.map((op)=><option>{op}</option>)}
                </datalist>
                </div>
         
                <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair-6">Row6AData, Row6BData and Row6CData</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row6AData"
                  list="lftb1listings"
                  onChange={(e) => setCreateInvestigation06(e.target.value)}
                /> 
                 <datalist id="lftb1listings">
                {lftb1listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row6BData"
                  list="lftb2listings"
                  onChange={(e) => setCreateResult06(e.target.value)}
                />
                  <datalist id="lftb2listings">
                {lftb2listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row6CData"
                  list="lftb3listings"
                  onChange={(e) => setCreateReference06(e.target.value)}
                />
                  <datalist id="lftb3listings">
                {lftb3listings.map((op)=><option>{op}</option>)}
                </datalist>
                </div>
         
         
                <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair-7">Row7AData, Row7BData and Row7CData</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row7AData"
                  list="lftb1listings"
                  onChange={(e) => setCreateInvestigation07(e.target.value)}
                /> 
                 <datalist id="lftb1listings">
                {lftb1listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row7BData"
                  list="lftb2listings"
                  onChange={(e) => setCreateResult07(e.target.value)}
                />
                  <datalist id="lftb2listings">
                {lftb2listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row7CData"
                  list="lftb3listings"
                  onChange={(e) => setCreateReference07(e.target.value)}
                />
                  <datalist id="lftb3listings">
                {lftb3listings.map((op)=><option>{op}</option>)}
                </datalist>
                </div>
              
         
         
                <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair-8">Row8AData, Row8BData and Row8CData</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row8AData"
                  list="lftb1listings"
                  onChange={(e) => setCreateInvestigation08(e.target.value)}
                /> 
                 <datalist id="lftb1listings">
                {lftb1listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row8BData"
                  list="lftb2listings"
                  onChange={(e) => setCreateResult08(e.target.value)}
                />
                  <datalist id="lftb2listings">
                {lftb2listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row8CData"
                  list="lftb3listings"
                  onChange={(e) => setCreateReference08(e.target.value)}
                />
                  <datalist id="lftb3listings">
                {lftb3listings.map((op)=><option>{op}</option>)}
                </datalist>
                </div>
              
         
                <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair-9">Row9AData, Row9BData and Row9CData</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row9AData"
                  list="lftb1listings"
                  onChange={(e) => setCreateInvestigation09(e.target.value)}
                /> 
                 <datalist id="lftb1listings">
                {lftb1listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row9BData"
                  list="lftb2listings"
                  onChange={(e) => setCreateResult09(e.target.value)}
                />
                  <datalist id="lftb2listings">
                {lftb2listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row9CData"
                  list="lftb3listings"
                  onChange={(e) => setCreateReference09(e.target.value)}
                />
                  <datalist id="lftb3listings">
                {lftb3listings.map((op)=><option>{op}</option>)}
                </datalist>
                </div>
              
              
                <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="pair-10">Row10AData, Row10BData and Row10CData</span>
              </div>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row10AData"
                  list="lftb1listings"
                  onChange={(e) => setCreateInvestigation10(e.target.value)}
                /> 
                 <datalist id="lftb1listings">
                {lftb1listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row10BData"
                  list="lftb2listings"
                  onChange={(e) => setCreateResult10(e.target.value)}
                />
                  <datalist id="lftb2listings">
                {lftb2listings.map((op)=><option>{op}</option>)}
                </datalist>
              <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Row10CData"
                  list="lftb3listings"
                  onChange={(e) => setCreateReference10(e.target.value)}
                />
                  <datalist id="lftb3listings">
                {lftb3listings.map((op)=><option>{op}</option>)}
                </datalist>
                </div>
              
              
                </form>
            
               
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary">Add Data</button>
                  </div>
                </div>
                </form>
              </div>
            </div>
         </>  
  )
}

export default ReportLftB