import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from "@mui/icons-material"
import { CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import "./featured.scss"

// fetch from db calculation
// const clients = 70;

const Featured = () => {
  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Total IVF Cases</h1>
       </div>
      <div className="bottom">
        <div className="featuredChart">
        <CircularProgressbar value={70} text={"70%"} 
        strokeWidth={5}
        />
        </div>
        <p className="title">Total entries made monthly</p>
        <p className="counts">1500</p>
        <p className="desc">History is updated on a daily basis</p>
        <div className="summary">
          <div className="item">
            <div className="itemTitle">
              Target
            </div>
            <div className="itemResult positive">
              <KeyboardArrowDownOutlined fontSize="small"/>
              <div className="resultCounts">
                10.2k
              </div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">
              Target
            </div>
            <div className="itemResult negative">
              <KeyboardArrowUpOutlined fontSize="small"/>
              <div className="resultCounts">
                10.2k
              </div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">
              Target
            </div>
            <div className="itemResult positive" >
              <KeyboardArrowDownOutlined fontSize="small"/>
              <div className="resultCounts">
                10.2k
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Featured