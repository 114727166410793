import "./drugs.scss"

const Drugs = () => {
  return (
    <div className="drugs">
      <h1>Pharmacy Drugs</h1>
    </div>
  )
}

export default Drugs