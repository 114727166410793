import "./ror.scss";


const Ror = () => {
  return (
    <div className="ror">
      <h1>Review of Results</h1>
        </div>
  )
}

export default Ror