import React, { useState } from 'react'
import { doc, updateDoc } from 'firebase/firestore'
import {db} from "../../firebase"
import 'bootstrap/dist/css/bootstrap.min.css';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { useParams } from 'react-router-dom';

const EditMedicals = ({id, weight, medication, otherHis}) => {

    
    const [createWeight, setCreateWeight] = useState(weight)
    const [createMedication, setCreateMedication] = useState(medication)
    const [createOthers, setCreateOthers] = useState(otherHis)

    const {userId} = useParams();


  const updateTask = async (e) => {
    e.preventDefault()
    try {
      const taskDocument = doc(db, "androcare/" + userId , "medicals", id);
      await updateDoc(taskDocument, {
        weight: createWeight,
        medication: createMedication,
        otherHis : createOthers,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <>
    <button
      type="button"
      className="btn btn-primary btn-sm"
      data-bs-toggle="modal"
      data-bs-target={`#id${id}`}>
      <EditNoteRoundedIcon/>
    </button>

    <div
      className="modal fade"
      id={`id${id}`}
      tabIndex="-1"
      aria-labelledby="editLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editLabel" style={{color:"green"}}>
              Update Medical History</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">

          <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-01">WEIGHT GAIN OR LOSS</span>
  </div>
              <input
                type="text"
                className="form-control"
                defaultValue={createWeight}
                onChange={e => setCreateWeight(e.target.value)}
              />
              </div>


              <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-02">DO YOU REACT TO MED.</span>
  </div>
              <input
                type="text"
                className="form-control"
                defaultValue={createMedication}
                onChange={e => setCreateMedication(e.target.value)}
              />
              </div>


              <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-03">OTHER HISTORY</span>
  </div>
    <input
      type="text"
      className="form-control"
      placeholder="Other History"
      defaultValue={createOthers}
      onChange={(e) => setCreateOthers(e.target.value)}
    />
    </div>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={e => updateTask(e)}
            >Update History</button>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default EditMedicals