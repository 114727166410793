import "./embryo.scss";
import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditEmbryo from './EditEmbryo';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

const Embryo = () => {

  const [tasks, setTasks] = useState([]);
  const [createDesc, setCreateDesc] = useState("")
  const [createDev, setCreateDev] = useState("")
  const [createGrade, setCreateGrade] = useState("")
  const [createQuality, setCreateQuality] = useState("")
  const [createDevice, setCreateDevice] = useState("")
  const [createStraw, setCreateStraw] = useState("")
  const [createStorage, setCreateStorage] = useState("")
  const [createDateThawed, setCreateDateThawed] = useState("")
  const [createDateFrozen, setCreateDateFrozen] = useState("")
  const [createStatus, setCreateStatus] = useState("")

  const {userId} = useParams();
  
  const collectionRef = collection(db, "androcare/" + userId , "embryo")

  useEffect(()=>{ 
    const getTasks = async () => {
      const q = query(collectionRef, orderBy('timeStamp', "asc"))
      await getDocs(q).then((task)=>{
       let tasksData = task.docs.map((doc) => ({...doc.data(),
      id: doc.id}))
      setTasks(tasksData)
      console.log("taskData", tasksData)
      }).catch((err) =>{
        console.log(err)
      })
    }
    getTasks()
  }, [])


     // Add Task Handler
     const submitTask = async (e) => {
      e.preventDefault();  
      try {
        await addDoc(collectionRef, {
          desc: createDesc,
          dev: createDev,
          grade: createGrade,
          quality: createQuality,
          device: createDevice,
          straw: createStraw,
          storage: createStorage,
          dateThawed : createDateThawed,
          dateFrozen : createDateFrozen,
          status: createStatus,
          timeStamp: serverTimestamp()
        })
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }


      //Delete Handler
     const deleteTask = async (id) => {
      try {
        window.confirm("Are you sure you want to delete this report?")
        const documentRef = doc(db, "androcare/" + userId , "embryo", id);
        await deleteDoc(documentRef)
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }

  return (
    <div className="embryo">
        <p>Embryo Preserved</p>

        <hr />

        {/* Modal Button */}
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#embryoModal">
        Add EmbryoPresv
        </button>

         {/* Table Components */}
    <div style={{marginTop: "30px"}}>
     <table className="styled-table">
       <thead>
         <tr>
           <th style={{textAlign: "center"}}>Description</th>
           <th style={{textAlign: "center"}}>Dev Stage</th>
           <th style={{textAlign: "center"}}>Grade</th>
           <th style={{textAlign: "center"}}>Quality</th>
           <th style={{textAlign: "center"}}>Device</th>
           <th style={{textAlign: "center"}}>Straw</th>
           <th style={{textAlign: "center"}}>Storage Location/Usage</th>
           <th style={{textAlign: "center"}}>Date Frozen</th>
           <th style={{textAlign: "center"}}>Date Thawed</th>
           <th style={{textAlign: "center"}}>Status</th>
           <th style={{textAlign: "center"}}>Action</th>
           <th style={{textAlign: "center"}}>Del</th>
         </tr>
       </thead>
       <tbody>
       
     {tasks.map(({id, desc, dev, grade, quality, device, straw, storage, dateFrozen, dateThawed, status}) =>
         <tr key={id}>
                     <td>{desc}</td>
                     <td>{dev}</td>
                     <td>{grade}</td>
                     <td>{quality}</td>
                     <td>{device}</td>
                     <td>{straw}</td>
                     <td>{storage}</td>
                     <td>{dateFrozen}</td>
                     <td>{dateThawed}</td>
                     <td>{status}</td>
                     <td> <button 
        type='button'
        className='icon'
        ><EditEmbryo id={id} dev={dev} desc={desc} grade={grade} quality={quality} device={device} straw={straw} storage={storage} dateFrozen={dateFrozen} dateThawed={dateThawed} status={status} />
        </button></td>
        <td>
        <button
        type="button"
         className="btn btn-danger float-end"
         onClick={() => deleteTask(id)}
       ><DeleteSweepIcon/></button>
        </td>
         </tr>
     )}

       </tbody>

   {/* Mapping those data in the doc alternative */}
       {/* <tbody>
         {Object.keys(tasks).map((id)=> {
           return(
             <tr>
               <td>{tasks[id].name}</td>
               <td>{tasks[id].date}</td>
               <td>{tasks[id].docto}</td>
               <td>{tasks[id].status}</td>
             </tr>
           )
         })}
       </tbody> */}
       {/* Mapping those data in the doc alternative */}


     </table>
    </div>
{/* Table Components */}

          {/* Modal */}
<div className="modal fade" id="embryoModal" tabindex="-1" aria-labelledby="embryoModalLabel" aria-hidden="true">
 <div className="modal-dialog">
 <form className="d-flex" onSubmit={submitTask}>
   <div className="modal-content">
     <div className="modal-header">
       <h1 className="modal-title fs-5" id="embryoModalLabel">Embryo Preservations</h1>
       <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div className="modal-body">
     
   <input
     type="text"
     className="form-control"
     placeholder="enter dev stage"
     onChange={(e) => setCreateDev(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     className="form-control"
     placeholder="enter description"
     onChange={(e) => setCreateDesc(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter grade"
     className="form-control"
     onChange={(e) => setCreateGrade(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter quality"
     className="form-control"
     onChange={(e) => setCreateQuality(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter devive"
     className="form-control"
     onChange={(e) => setCreateDevice(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter straw"
     className="form-control"
     onChange={(e) => setCreateStraw(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter storage"
     className="form-control"
     onChange={(e) => setCreateStorage(e.target.value)}
     size="3080"
   />

<div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">DATE THAWED</span>
  </div>
  <input
      type="date"
      className="form-control"
      onChange={(e) => setCreateDateThawed(e.target.value)}
    /> 
    </div>     


<div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">DATE FROZEN</span>
  </div>
  <input
      type="date"
      className="form-control"
      onChange={(e) => setCreateDateFrozen(e.target.value)}
    /> 
    </div> 


   <input
     type="text"
     placeholder="set status"
     className="form-control"
     onChange={(e) => setCreateStatus(e.target.value)}
     size="3080"
   />
   

   
     </div>
     <div className="modal-footer">
       <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button type="submit" className="btn btn-primary">Add EmbryoPresv</button>
     </div>
   </div>
   </form>
 </div>
</div>


        </div>
  )
}

export default Embryo