import React, { useState } from 'react'
import { doc, updateDoc } from 'firebase/firestore'
import {db} from "../../firebase"
import 'bootstrap/dist/css/bootstrap.min.css';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import { useParams } from 'react-router-dom';


const EditHusbandMedicals = ({id, medication, surgery}) => {

    const [createMedication, setCreateMedication] = useState(medication)
    const [createSurgery, setCreateSurgery] = useState(surgery)

    const {userId} = useParams();


    const updateTask = async (e) => {
      e.preventDefault()
      try {
        const taskDocument = doc(db, "androcare/" + userId , "husbandMedicals", id);
        await updateDoc(taskDocument, {
            medication: createMedication,
            surgery : createSurgery,
        });
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  
  return (
    <>
    <button
      type="button"
      className="btn btn-primary btn-sm"
      data-bs-toggle="modal"
      data-bs-target={`#id${id}`}>
      <EditNoteRoundedIcon/>
    </button>

    <div
      className="modal fade"
      id={`id${id}`}
      tabIndex="-1"
      aria-labelledby="editLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editLabel" style={{color:"green"}}>
              Update Medical History</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">

               
          <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="pair-01">DO YOU REACT TO MED.</span>
        </div>
          <input
            type="text"
            className="form-control"
            placeholder="Do you react to medication.."
            defaultValue={createMedication}
            onChange={(e) => setCreateMedication(e.target.value)}
          />
          </div>
      
          <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="pair-02">HAVE YOU DONE SURGERY BEFORE</span>
        </div>
          <input
            type="text"
            className="form-control"
            placeholder="Have you done surgery before..."
            defaultValue={createSurgery}
            onChange={(e) => setCreateSurgery(e.target.value)}
          />
          </div>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={e => updateTask(e)}
            >Update History</button>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default EditHusbandMedicals