import "./viewreportecs.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import logo from "../../bgImg/logo.png"
import HomeIcon from '@mui/icons-material/Home';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

const ViewReportEcs = () => {

  const [ecs, setEcs] = useState([]);
  const [users, setUsers] = useState([]);

  const {userId} = useParams();
  let id = userId

  useEffect(() => {
    const getUsers = async (id) =>{
      const docRef = doc(db, `androcare/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData)
        console.log("Document data:", theData);
      } else {
        setUsers({})
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    getUsers(id);
  }, [id])

  const {ecsId} = useParams();

  // let id = mcsId

  useEffect(() => {
      const getMcs = async () =>{
        const docRef = doc(db, "androcare/" + userId , "reportecs", ecsId);
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists()) {
          let theData = docSnap.data();
          setEcs(theData)
          console.log("Document data:", theData);
        } else {
          setEcs({})
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      };
      getMcs(ecsId);
    }, [ecsId])


  return (
    <>
    <div className="viewreportecs">
    <div className="headerecs">
    <div>    
    <h1><img src={logo} alt="reportLogo" />&nbsp;Your IVF Clinic</h1>
    </div> 

    <span>
    <i><HomeIcon className="icon"/>&nbsp;Address: Your IVF Address
   </i>
   <br />
   <i></i>
    <br />
    <i><PhoneInTalkIcon className="icon"/>&nbsp;Contact: Your IVF Contact</i>
    <br />
    <i><AlternateEmailIcon className="icon"/>&nbsp;Email: Your IVF Email Address</i>
    <br />
    <i><OpenInBrowserIcon className="icon"/>&nbsp;Website: Your IVF Website</i>
    <br />
    <i><LocalHospitalIcon className="icon"/>&nbsp;Portal: Your IVF Portal</i>
    </span>
        </div>

        <hr/>
    
    <div className="ecspatientsdetail">
    <div>
        <p><strong>Full Name:</strong>&nbsp;{users.title}&nbsp;{users.firstName}&nbsp;{users.lastName}</p>
        <p><strong>Email Address:</strong>&nbsp;{users.email}</p>
        <p><strong>Date:</strong>&nbsp;{ecs.date}</p>
        </div>
        <div>
        <p><strong>Age:</strong>&nbsp;{users.age}</p>
        <p><strong>Phone Number:</strong>&nbsp;{users.phoneNumber}</p>
        <p><strong>Occupation:</strong>&nbsp;{users.occupation}</p>
        </div>
        <div>
        <p><strong>Sex:</strong>&nbsp;{users.sex}</p>
        <p><strong>Registration No:</strong>&nbsp;{users.regNo}</p>
        <p><strong>State of Origin:</strong>&nbsp;{users.state}</p>
        </div>
    </div>

    <hr/>

    <div className="header">
          <h2 style={{textAlign: "center", fontWeight: "bold"}}>{ecs.reportTitle}</h2>
          <u><h4 style={{textAlign: "center", fontWeight: "bold"}}>{ecs.subReportTitle}</h4></u>
          <u><p style={{textAlign: "center"}}>{ecs.subHeading}</p></u>
    </div>

    <div className="coverageecs">
    <div className="overallecs" >
    <div className="headerecs">
          <p>{ecs.row1Header}</p>
          <p>{ecs.row2Header}</p> 
    </div>
    <div className="contentecs">

      {/* Antibiotics vis sensitivity */}
      <section style={{marginTop: "8px"}}>
      <section className="cms">
      <p>{ecs.antibiotics01}</p>
      </section>
      <section className="cms" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ecs.sensitivity01}</p>
      </section>
      </section>
      {/* Antibiotics vis sensitivity */}

      {/* Antibiotics vis sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="cms">
      <p>{ecs.antibiotics02}</p>
      </section>
      <section className="cms" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ecs.sensitivity02}</p>
      </section>
      </section>
      {/* Antibiotics vis sensitivity */}


      {/* Antibiotics vis sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="cms">
      <p>{ecs.antibiotics03}</p>
      </section>
      <section className="cms" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ecs.sensitivity03}</p>
      </section>
      </section>
      {/* Antibiotics vis sensitivity */}


      {/* Antibiotics vis sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="cms">
      <p>{ecs.antibiotics04}</p>
      </section>
      <section className="cms" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ecs.sensitivity04}</p>
      </section>
      </section>
      {/* Antibiotics vis sensitivity */}


      {/* Antibiotics vis sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="cms">
      <p>{ecs.antibiotics05}</p>
      </section>
      <section className="cms" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ecs.sensitivity05}</p>
      </section>
      </section>
      {/* Antibiotics vis sensitivity */}

        {/* Antibiotics vis sensitivity */}
        <section style={{marginTop: "-38px"}}>
      <section className="cms">
      <p>{ecs.antibiotics06}</p>
      </section>
      <section className="cms" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ecs.sensitivity06}</p>
      </section>
      </section>
      {/* Antibiotics vis sensitivity */}

       {/* Antibiotics vis sensitivity */}
       <section style={{marginTop: "-38px"}}>
      <section className="cms">
      <p>{ecs.antibiotics07}</p>
      </section>
      <section className="cms" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ecs.sensitivity07}</p>
      </section>
      </section>
      {/* Antibiotics vis sensitivity */}

       {/* Antibiotics vis sensitivity */}
       <section style={{marginTop: "-38px"}}>
      <section className="cms">
      <p>{ecs.antibiotics08}</p>
      </section>
      <section className="cms" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ecs.sensitivity08}</p>
      </section>
      </section>
      {/* Antibiotics vis sensitivity */}


      {/* Antibiotics vis sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="cms">
      <p>{ecs.antibiotics09}</p>
      </section>
      <section className="cms" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ecs.sensitivity09}</p>
      </section>
      </section>
      {/* Antibiotics vis sensitivity */}


      {/* Antibiotics vis sensitivity */}
      <section style={{marginTop: "-38px"}}>
      <section className="cms">
      <p>{ecs.antibiotics10}</p>
      </section>
      <section className="cms" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ecs.sensitivity10}</p>
      </section>
      </section>
      {/* Antibiotics vis sensitivity */}


      </div>
    </div>
    </div>

    <br />
    <br />
    <br />

    <div className="sTags">
      <span>
      <p style={{fontWeight: "bolder"}}>R= Resistant</p>
      <p style={{fontWeight: "bolder"}}>S= Sensitive</p>
      </span>
    </div>

        {/* Ending Div */}
    </div>
      </>
  )
}

export default ViewReportEcs