import "./pharmacy.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import Drugs from "../../components/drugs/Drugs";
import TrackDrugs from "../../components/trackdrugs/TrackDrugs";

const Pharmacy = () => {
  return (
    <div className='pharmacy'>
    <Sidebar/>
    <div className="pharmacyContainer">
    <Navbar/>
    <div className="pharmacyD">
    <Drugs/>
    </div>
    <div className="tdrugs">
      <TrackDrugs/>
    </div>
    </div>
    </div>
  )
}

export default Pharmacy