import "./viewreporturinalysis.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import logo from "../../bgImg/logo.png"
import HomeIcon from '@mui/icons-material/Home';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';


const ViewReportUrinalysis = () => {

  const [ury, setUry] = useState([]);
  const [users, setUsers] = useState([]);

  const {userId} = useParams();
  let id = userId

  useEffect(() => {
    const getUsers = async (id) =>{
      const docRef = doc(db, `androcare/${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUsers(theData)
        console.log("Document data:", theData);
      } else {
        setUsers({})
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    getUsers(id);
  }, [id])


  const {uryId} = useParams();

  // let id = mcsId

 
  useEffect(() => {
    const getMcs = async () =>{
      const docRef = doc(db, "androcare/" + userId , "reportUrinalysis", uryId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let theData = docSnap.data();
        setUry(theData)
        console.log("Document data:", theData);
      } else {
        setUry({})
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    getMcs(uryId);
  }, [uryId])


  return (
    <>
    <div className="viewreporturinalysis">
        <div className="headerury">
        <div>    
    <h1><img src={logo} alt="reportLogo" />&nbsp;Your IVF Clinic</h1>
    </div> 

    <span>
    <i><HomeIcon className="icon"/>&nbsp;Address: Your IVF Address
   </i>
   <br />
   <i></i>
    <br />
    <i><PhoneInTalkIcon className="icon"/>&nbsp;Contact: Your IVF Contact</i>
    <br />
    <i><AlternateEmailIcon className="icon"/>&nbsp;Email: Your IVF Email Address</i>
    <br />
    <i><OpenInBrowserIcon className="icon"/>&nbsp;Website: Your IVF Website</i>
    <br />
    <i><LocalHospitalIcon className="icon"/>&nbsp;Portal: Your IVF Portal</i>
    </span>
        </div>

        <hr/>
    
    <div className="urypatientsdetail">
    <div>
        <p><strong>Full Name:</strong>&nbsp;{users.title}&nbsp;{users.firstName}&nbsp;{users.lastName}</p>
        <p><strong>Email Address:</strong>&nbsp;{users.email}</p>
        <p><strong>Date:</strong>&nbsp;{ury.date}</p>
        </div>
        <div>
        <p><strong>Age:</strong>&nbsp;{users.age}</p>
        <p><strong>Phone Number:</strong>&nbsp;{users.phoneNumber}</p>
        <p><strong>Occupation:</strong>&nbsp;{users.occupation}</p>
        </div>
        <div>
        <p><strong>Sex:</strong>&nbsp;{users.sex}</p>
        <p><strong>Registration No:</strong>&nbsp;{users.regNo}</p>
        <p><strong>State of Origin:</strong>&nbsp;{users.state}</p>
        </div>
    </div>

    <hr/>

    <div className="header" style={{marginTop : "15px"}}>
          <h2 style={{textAlign: "center", fontWeight: "bold"}}>{ury.durinalysisTitle}</h2>
    </div>

    <div className="coverageury">
    <div className="overallury">
    <div className="headerury">
    </div>
    <div className="contentury">

      {/* urinalysis format */}
      <section style={{marginTop: "8px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <u><p style={{fontWeight : "bold"}}>{ury.dmacroscopyheader}</p></u>
     </section>
      </section>
     {/* urinalysis format */}


      {/* urinalysis format */}
      <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{ury.dmacroscopycell01}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ury.dmacroscopyvalue01}</p>
      </section>
      </section>
     {/* urinalysis format */}


    {/* urinalysis format */}
    <section style={{marginTop: "8px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <u><p style={{fontWeight : "bold"}}>{ury.dmicroscopyheader}</p></u>
     </section>
      </section>
     {/* urinalysis format */}



      {/* urinalysis format */}
      <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{ury.dmicroscopycell01}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ury.dmicroscopyvalue01}</p>
      </section>
      </section>
     {/* urinalysis format */}


      {/* urinalysis format */}
      <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{ury.dmicroscopycell02}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ury.dmicroscopyvalue02}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{ury.dmicroscopycell03}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ury.dmicroscopyvalue03}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{ury.dmicroscopycell04}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ury.dmicroscopyvalue04}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{ury.dmicroscopycell05}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ury.dmicroscopyvalue05}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{ury.dmicroscopycell06}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ury.dmicroscopyvalue06}</p>
      </section>
      </section>
     {/* urinalysis format */}


       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{ury.dmicroscopycell07}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ury.dmicroscopyvalue07}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{ury.dmicroscopycell08}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ury.dmicroscopyvalue08}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{ury.dmicroscopycell09}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ury.dmicroscopyvalue09}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{ury.dmicroscopycell10}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{ury.dmicroscopyvalue10}</p>
      </section>
      </section>
     {/* urinalysis format */}

     </div>
    </div>
    </div>


    
    <div className="sTags">
      <span>
      <p style={{fontWeight: "bolder"}}>R= Resistant</p>
      <p style={{fontWeight: "bolder"}}>S= Sensitive</p>
      </span>
    </div>

    <div className="chby">
      <p>Checked By</p>
    </div>

    <div className="sTags">
      <span>
      <p style={{textAlign: "center"}}>{ury.checkedby}</p>
      <hr style={{marginTop : "-12px"}} />
      <p style={{fontWeight: "bolder", marginTop : "-9px"}}>Med. Lab. Scientist in Charge</p>
      </span>
    </div>

    {/* Ending Div */}
      </div>
      </>
  )
}

export default ViewReportUrinalysis