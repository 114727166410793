import "./single.scss"
import CaseFile from './../../components/casefile/CaseFile';
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import Spouse from "../../components/spouse/Spouse";


const Single = () => {
  return (
    <div className='single'>
      <Sidebar/>
      <div className="singleContainer">
      <Navbar/>
      <div className="spu">
      <Spouse/>
      </div>
      <CaseFile/>
      </div>
      </div>
  )
}

export default Single