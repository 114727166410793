import "./obstetrics.scss";
import {useEffect, useState} from 'react'
import {db} from "../../firebase"
import { addDoc, collection, doc, getDocs, query, runTransaction, serverTimestamp } from 'firebase/firestore'
import EditObstetrics from "./EditObstetrics";
import { useParams } from "react-router-dom";

const Obstetrics = () => {

  const [tasks, setTasks] = useState([]);
  const [createChildren, setCreateChildren] = useState("");
  const [checked, setChecked] = useState([]);

  const {userId} = useParams();

  const collectionRef = collection(db, "androcare/" + userId , "obstetrics")

  useEffect(()=>{
    const getTasks = async () => {
      const q = query(collectionRef)
      await getDocs(q).then((list)=>{
       let tasksData = list.docs.map((doc) => ({...doc.data(),
      id: doc.id}))
      setTasks(tasksData)
      setChecked(tasksData)
      }).catch((err) =>{
        console.log(err)
      })
    }
    getTasks()
  }, [])


  const submitTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collectionRef, {
        children: createChildren,
        isYes: false,
        isNo: false,
        timeStamp: serverTimestamp()
      })
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }


// isYes
const checkBoxYes = async (e, weight) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isYes: !state[indexToUpdate].isYes,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "obstetrics" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isYes;
    transaction.update(docRef, { isYes: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}


// isNo
const checkBoxNo = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isNo: !state[indexToUpdate].isNo,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "obstetrics" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isNo;
    transaction.update(docRef, { isNo: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}
  


  return (

    <>
    <div className="obstetrics">
        Obstetrics History
        <hr />

        {/* Modal Button */}
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#obstetricsModal">
          Add History
        </button>
        {/* Modal Button */}

      {/* Mapped Area */}
      {tasks.map(({id, children, isYes, isNo})=>
      <>
      <div className="obstetricshistory" key={id}>
        <div className="obstetricsdata">
        <div className="obstetricsdata1">
          <span>

          <span className="pim">
          <p><strong>Have you gotten pregnant before:&nbsp;</strong></p>
          <input type="checkbox" 
           defaultChecked={isYes}
           onChange={(e) => checkBoxYes(e)}  
           name={id}
          /> <p>Yes</p> 
          <input type="checkbox" 
           defaultChecked={isNo}
           onChange={(e) => checkBoxNo(e)}  
           name={id}
          /> <p>No</p> 
          </span>
          <p style={{fontSize:18, color:"#ca4200"}}><strong>No of Children:&nbsp;</strong> {children} </p>

          </span>
        </div>
        </div>
      </div>

      <div class="col-md-12 text-end">
      {/* Our lovely button */}
      <button
      type='button'
      className='btn-pri'
      ><EditObstetrics id={id} children={children}/></button>
      </div>
      </>
)}


    
         {/* Modal */}
         <div className="modal fade" id="obstetricsModal" tabindex="-1" aria-labelledby="obstetricsModalLabel" aria-hidden="true">
  <div className="modal-dialog">
  <form className="d-flex" onSubmit={submitTask}>
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="obstetricsModalLabel">Obstetrics History</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      
    <input
      type="text"
      className="form-control"
      placeholder="Enter No. of Children"
      onChange={(e) => setCreateChildren(e.target.value)}
    />
   
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary">Add Obstetrics</button>
      </div>
    </div>
    </form>
  </div>
</div>


        </div>

        </>
  )
}

export default Obstetrics