import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDjkV6qduTtJDO6ALkZ7-3ap6e1EsIH6_s",
  authDomain: "androcaredb.firebaseapp.com",
  projectId: "androcaredb",
  storageBucket: "androcaredb.appspot.com",
  messagingSenderId: "597356146852",
  appId: "1:597356146852:web:5cf7f61bac326f9a7b3631"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

export const storage = getStorage();

