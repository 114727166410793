import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { db } from '../../firebase';


const EditTrackBell = ({id, date, serviceName, serviceType, serviceTime, serviceTag}) => {

    const [createDate, setCreateDate] = useState(date)
    const [createName, setCreateName] = useState(serviceName)
    const [createType, setCreateType] = useState(serviceType)
    const [createTag, setCreateTag] = useState(serviceTag)
    const [createTime, setCreateTime] = useState(serviceTime)

    const {userId} = useParams();

    const updateData = async (e) => {
      e.preventDefault()
      try {
        const taskDocument = doc(db, "androcare/" + userId , "appointment", id);
        await updateDoc(taskDocument, {
            date: createDate,
            serviceName: createName,
            serviceType: createType,
            serviceTime: createTime,
            serviceTag: createTag,
        });
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  return (
    <>
    {/* Modal Button */}
    <button
    type="button"
    className="btn btn-primary btn-sm"
    data-bs-toggle="modal"
    data-bs-target={`#id${id}`}>
    <EditNoteIcon/>
  </button>

    
  <div
    className="modal fade"
    id={`id${id}`}
    tabIndex="-1"
    aria-labelledby="editLabel"
    aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="editLabel" style={{color:"grey"}}>
            Update Notifier</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">


        <form>
    <input
      type="date"
      className="form-control"
      defaultValue={createDate}
      onChange={(e) => setCreateDate(e.target.value)}
    />
    <input
      type="text"
      placeholder="enter service name"
      className="form-control"
      defaultValue={createName}
      onChange={(e) => setCreateName(e.target.value)}
      size="3080"
    />
    <input
      type="text"
      placeholder="enter service type"
      className="form-control"
      defaultValue={createType}
      onChange={(e) => setCreateType(e.target.value)}
      size="3080"
    />
    <input
      type="time"
      placeholder="time designated"
      className="form-control"
      defaultValue={createTime}
      onChange={(e) => setCreateTime(e.target.value)}
      size="3080"
    />
    <input
      type="text"
      placeholder="enter tag allocated"
      className="form-control"
      defaultValue={createTag}
      onChange={(e) => setCreateTag(e.target.value)}
      size="3080"
    />

</form>
        

        </div>

          <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => updateData(e)}
          >Update Notifier</button>
        </div>
      </div>
    </div>
  </div>

</>
  )
}

export default EditTrackBell