import "./global.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../datatablesource";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";

import { collection,deleteDoc,doc,onSnapshot, orderBy, query, where } from "firebase/firestore";
import {db} from "../../firebase"



const Global = () => {

    const [data, setData] = useState([]);


  useEffect(() =>{
    // LISTEN (REALTIME)
    const collectionRef = collection(db, "androcare");
    const q = query(collectionRef, orderBy('timeStamp', "asc"))
    const unsub = onSnapshot(
      q,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list = [{ id: doc.id, ...doc.data() }, ...list]
         
        });
        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );
    
    return () => {
      unsub();
    };
    }, []); 

            {/* Disabling delete function */}
//   const handleDelete = async (id) => {
//     try {
//       if(window.confirm("Are you sure you want to delete this document?")){
//         await deleteDoc(doc(db, "androcare", id));
//         setData(data.filter((item) => item.id !== id));
//       } else{
        
//       }
//     } catch (err) {

//     }
//   };

  
const actionColumn = [
  
  { field: "action", headerName: "Action", width: 150, renderCell:(params)=>{
    return (
      <div className="cellAction">

        <Link to={`/users/${params.row.id}/casefolder`}>
        <Tooltip title="View Client" placement="bottom">
        <div className="viewButton"><VisibilityIcon/></div>
        </Tooltip>
        </Link>

        <Link to={`/users/${params.row.id}`}>
        <Tooltip title="Edit Client" placement="bottom">
        <div className="editButton"><EditNoteIcon/></div>
        </Tooltip>
        </Link>

      {/* Disabling delete function */}
        {/* <Tooltip title="Delete Client" placement="bottom">
        <div className="deleteButton"  onClick={() => handleDelete(params.row.id)}><DeleteIcon/></div>
        </Tooltip>  */}
        
      </div>
    )
  }}
]


  return (
    <div className='global'>

      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 9},
          },
        }}
        pageSizeOptions={[9, 10]}
      />

    </div>
  )
}

export default Global