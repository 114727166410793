import "./nursing.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import NurseNotes from "../../components/nursenotes/NurseNotes";
import Nursen from "../../components/nursen/Nursen";

const Nursing = () => {
  return (
    <div className='nursing'>
    <Sidebar/>
    <div className="nursingContainer">
    <Navbar/>
    <div className="nnotes">
    <NurseNotes/>
    </div>
    <div className="nnt">
      <Nursen/>
    </div>
    </div>
    </div>
  )
}

export default Nursing