import "./sperm.scss";
import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditSperm from './EditSperm';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

const Sperm = () => {

  
  const [tasks, setTasks] = useState([]);
  const [createName, setCreateName] = useState("")
  const [createFrozen, setCreateFrozen] = useState("")
  const [createThawed, setCreateThawed] = useState("")
  const [createStraw, setCreateStraw] = useState("")
  const [createStatus, setCreateStatus] = useState("")
  const [createStorage, setCreateStorage] = useState("")
  const [createDateThawed, setCreateDateThawed] = useState("")
  const [createDateFrozen, setCreateDateFrozen] = useState("")

  const {userId} = useParams();
  
  const collectionRef = collection(db, "androcare/" + userId , "sperm")

  useEffect(()=>{ 
    const getTasks = async () => {
      const q = query(collectionRef, orderBy('timeStamp', "asc"))
      await getDocs(q).then((task)=>{
       let tasksData = task.docs.map((doc) => ({...doc.data(),
      id: doc.id}))
      setTasks(tasksData)
      console.log("taskData", tasksData)
      }).catch((err) =>{
        console.log(err)
      })
    }
    getTasks()
  }, [])


     // Add Task Handler
     const submitTask = async (e) => {
      e.preventDefault();  
      try {
        await addDoc(collectionRef, {
          name: createName,
          straw: createStraw,
          frozen: createFrozen,
          thawed: createThawed,
          status: createStatus,
          storage: createStorage,
          dateThawed : createDateThawed,
          dateFrozen : createDateFrozen,
          timeStamp: serverTimestamp()
        })
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }


      //Delete Handler
      const deleteTask = async (id) => {
        try {
          window.confirm("Are you sure you want to delete this report?")
          const documentRef = doc(db, "androcare/" + userId , "sperm", id);
          await deleteDoc(documentRef)
          window.location.reload();
        } catch (err) {
          console.log(err);
        }
      }

  return (
    <div className='sperm'>
        <p>Sperm Preserved</p>

        <hr />


        {/* Modal Button */}
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#spermModal">
        Add SpermPresv
        </button>

         {/* Table Components */}
    <div style={{marginTop: "30px"}}>
     <table className="styled-table">
       <thead>
         <tr>
           <th style={{textAlign: "center"}}>Name</th>
           <th style={{textAlign: "center"}}>Straw Number</th>
           <th style={{textAlign: "center"}}>Number Frozen</th>
           <th style={{textAlign: "center"}}>Number Thawed</th>
           <th style={{textAlign: "center"}}>Storage Location</th>
           <th style={{textAlign: "center"}}>Date Frozen</th>
           <th style={{textAlign: "center"}}>Date Thawed</th>
           <th style={{textAlign: "center"}}>Payment Status</th>
           <th style={{textAlign: "center"}}>Action</th>
           <th style={{textAlign: "center"}}>Del</th>
         </tr>
       </thead>
       <tbody>
       
     {tasks.map(({id, name, straw, frozen, thawed, status, storage, dateFrozen, dateThawed}) =>
         <tr key={id}>
                     <td>{name}</td>
                     <td>{straw}</td>
                     <td>{frozen}</td>
                     <td>{thawed}</td>
                     <td>{storage}</td>
                     <td>{dateFrozen}</td>
                     <td>{dateThawed}</td>
                     <td>{status}</td>
                     <td> <button 
        type='button'
        className='icon'
        ><EditSperm id={id} status={status} name={name} straw={straw} frozen={frozen} thawed={thawed} storage={storage} dateFrozen={dateFrozen} dateThawed={dateThawed} />
        </button></td>
        <td>
        <button
        type="button"
         className="btn btn-danger float-end"
         onClick={() => deleteTask(id)}
       ><DeleteSweepIcon/></button>
        </td>
         </tr>
     )}

       </tbody>

   {/* Mapping those data in the doc alternative */}
       {/* <tbody>
         {Object.keys(tasks).map((id)=> {
           return(
             <tr>
               <td>{tasks[id].name}</td>
               <td>{tasks[id].date}</td>
               <td>{tasks[id].docto}</td>
               <td>{tasks[id].status}</td>
             </tr>
           )
         })}
       </tbody> */}
       {/* Mapping those data in the doc alternative */}


     </table>
    </div>
{/* Table Components */}

          {/* Modal */}
<div className="modal fade" id="spermModal" tabindex="-1" aria-labelledby="spermModalLabel" aria-hidden="true">
 <div className="modal-dialog">
 <form className="d-flex" onSubmit={submitTask}>
   <div className="modal-content">
     <div className="modal-header">
       <h1 className="modal-title fs-5" id="spermModalLabel">Sperm Preservations</h1>
       <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div className="modal-body">


   <input
     type="text"
     className="form-control"
     placeholder="enter name"
     onChange={(e) => setCreateName(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     className="form-control"
     placeholder="enter number of straw"
     onChange={(e) => setCreateStraw(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter number frozen"
     className="form-control"
     onChange={(e) => setCreateFrozen(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter number thawed"
     className="form-control"
     onChange={(e) => setCreateThawed(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter storage location"
     className="form-control"
     onChange={(e) => setCreateStorage(e.target.value)}
     size="3080"
   />


<div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">DATE THAWED</span>
  </div>
  <input
      type="date"
      className="form-control"
      onChange={(e) => setCreateDateThawed(e.target.value)}
    /> 
    </div>     


<div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">DATE FROZEN</span>
  </div>
  <input
      type="date"
      className="form-control"
      onChange={(e) => setCreateDateFrozen(e.target.value)}
    /> 
    </div>     



   <input
     type="text"
     placeholder="set payment status"
     className="form-control"
     onChange={(e) => setCreateStatus(e.target.value)}
     size="3080"
   />
  
   
     </div>
     <div className="modal-footer">
       <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button type="submit" className="btn btn-primary">Add SpermPresv</button>
     </div>
   </div>
   </form>
 </div>
</div>


        </div>
  )
}

export default Sperm