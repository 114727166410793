import "./trackreview.scss";
import { addDoc, collection, doc, getDocs, orderBy, query, runTransaction, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditTrackReview from "./EditTrackReview";

const TrackReview = () => {

  const reviewHeader = ["Sperm Quality", "Ovarian Function", "Womb Integrity", "Medical Fitness"]

  const spermQuality = ["Satisfactory", "Asthesnospermia", "Oligospermia", "Teratospermia", "Azoospermia"]

  const ovarianFunction = ["POR", "Satisfactory", "HOR-PCOS"]

  const wombIntegrity = ["Satisfactory", "Myoma", "Polyp", "Adhesions"]

  const medicalFitness = ["Satisfactory", "Hypertension", "Diabetes", "Obesity"]

    const [tasks, setTasks] = useState([]);
    const [checked, setChecked] = useState([]);
    const [reviewHeader01, setReviewHeader01] = useState("")
    const [reviewHeader02, setReviewHeader02] = useState("")
    const [reviewHeader03, setReviewHeader03] = useState("")
    const [reviewHeader04, setReviewHeader04] = useState("")
    const [createSperm, setCreateSperm] = useState("")
    const [createSperm01, setCreateSperm01] = useState("")
    const [createSperm02, setCreateSperm02] = useState("")
    const [createSperm03, setCreateSperm03] = useState("")
    const [createOvarian, setCreateOvarian] = useState("")
    const [createOvarian01, setCreateOvarian01] = useState("")
    const [createOvarian02, setCreateOvarian02] = useState("")
    const [createOvarian03, setCreateOvarian03] = useState("")
    const [createWomb, setCreateWomb] = useState("")
    const [createWomb01, setCreateWomb01] = useState("")
    const [createWomb02, setCreateWomb02] = useState("")
    const [createWomb03, setCreateWomb03] = useState("")
    const [createMedical, setCreateMedical] = useState("")
    const [createMedical01, setCreateMedical01] = useState("")
    const [createMedical02, setCreateMedical02] = useState("")
    const [createMedical03, setCreateMedical03] = useState("")
    const [createConclusion, setCreateConclusion] = useState("")
    const [createRemark, setCreateRemark] = useState("")
    const [createPlan, setCreatePlan] = useState("")
    const [createReport, setCreateReport] = useState("");


    const {userId} = useParams();
  
    const collectionRef = collection(db, "androcare/" + userId , "reviewresult")

    useEffect(()=>{ 
        const getTasks = async () => {
          const q = query(collectionRef, orderBy("timeStamp", "desc"))
          await getDocs(q).then((task)=>{
           let tasksData = task.docs.map((doc) => ({...doc.data(),
          id: doc.id}))
          setTasks(tasksData)
          setChecked(tasksData)
          }).catch((err) =>{
            console.log(err)
          })
        }
        getTasks()
      }, [])

       // Add Task Handler
    const submitTask = async (e) => {
        e.preventDefault();  
        try {
          await addDoc(collectionRef, {
            reviewHeader1 : reviewHeader01,
            reviewHeader2 : reviewHeader02,
            reviewHeader3 : reviewHeader03,
            reviewHeader4 : reviewHeader04,
            sperm: createSperm,
            sperm01: createSperm01,
            sperm02: createSperm02,
            sperm03: createSperm03,
            ovarian: createOvarian,
            ovarian01: createOvarian01,
            ovarian02: createOvarian02,
            ovarian03: createOvarian03,
            womb: createWomb,
            womb01: createWomb01,
            womb02: createWomb02,
            womb03: createWomb03,
            medical: createMedical,
            medical01: createMedical01,
            medical02: createMedical02,
            medical03: createMedical03,
            conclusion: createConclusion,
            remark: createRemark,
            plan: createPlan,
            isYes: false,
            reported: createReport,
            timeStamp: serverTimestamp()
          })
          window.location.reload();
        } catch (err) {
          console.log(err);
        }
      }

// Yes
const checkBoxYes = async (e) =>{
  setChecked(state => {
    const indexToUpdate = state.findIndex(checkBox => checkBox.id.toString() === e.target.name);
    let newState = state.slice()
    newState.splice(indexToUpdate, 1, {
      ...state[indexToUpdate],
      isYes: !state[indexToUpdate].isYes,

    })
    setTasks(newState)
    return newState
})

// Persisting the checked Value

try {
  const docRef = doc(db, "androcare/" + userId , "reviewresult" , e.target.name);
  await runTransaction(db, async (transaction) => {
    const taskDoc = await transaction.get(docRef);
    if (!taskDoc.exists()) {
      throw "Document does not exist!";
    }
    const newValue = !taskDoc.data().isYes;
    transaction.update(docRef, { isYes: newValue });
  });
  console.log("Transaction successfully committed!");
} catch (error) {
  console.log("Transaction failed: ", error);
}
}
  
    
  return (
    <>
    <div className="trackreview">
        <p>Review Of Results</p>

        <hr />
        
        {/* Modal Button */}
        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#trackRevsModal">
           Add Review
           </button>


     

     {/* Mapped Area */}
     {tasks.map(({id,reviewHeader1,reviewHeader2, reviewHeader3, reviewHeader4, sperm, sperm01, sperm02, sperm03, ovarian, ovarian01, ovarian02, ovarian03, womb, womb01, womb02, womb03, medical,medical01, medical02, medical03, conclusion, remark, plan, reported, isYes, timeStamp})=>
        <>
    <div className="coveragetrv">
    <div className="overalltrv"  key={id}>
    <div className="headertrv">
          <p>{reviewHeader1}</p>
          <p>{reviewHeader2}</p>  
          <p>{reviewHeader3}</p>
          <p>{reviewHeader4}</p>
    </div>
    <div className="contenttrv">


      {/* Male Hormonal Assay Data*/}
      <section style={{marginTop: "8px"}}>
      <section className="cms" style={{marginLeft: "114px"}}>
      <p>{sperm}</p>
      </section>
      <section className="cms" style={{marginTop: "-40.0px", marginLeft: "345px"}}>
      <p>{ovarian}</p>
      </section>
      <section className="cms" style={{marginTop: "-40.0px", marginLeft: "600px"}}>
      <p>{womb}</p>
      </section>
      <section className="cms" style={{marginTop: "-40.0px", marginLeft: "830px"}}>
      <p>{medical}</p>
      </section>
      </section>
      {/* Male Hormonal Assay Data*/}


      {/* Male Hormonal Assay Data*/}
      <section style={{marginTop: "8px"}}>
      <section className="cms" style={{marginLeft: "114px"}}>
      <p>{sperm01}</p>
      </section>
      <section className="cms" style={{marginTop: "-40.0px", marginLeft: "345px"}}>
      <p>{ovarian01}</p>
      </section>
      <section className="cms" style={{marginTop: "-40.0px", marginLeft: "600px"}}>
      <p>{womb01}</p>
      </section>
      <section className="cms" style={{marginTop: "-40.0px", marginLeft: "830px"}}>
      <p>{medical01}</p>
      </section>
      </section>
      {/* Male Hormonal Assay Data*/}


      {/* Male Hormonal Assay Data*/}
      <section style={{marginTop: "8px"}}>
      <section className="cms" style={{marginLeft: "114px"}}>
      <p>{sperm02}</p>
      </section>
      <section className="cms" style={{marginTop: "-40.0px", marginLeft: "345px"}}>
      <p>{ovarian02}</p>
      </section>
      <section className="cms" style={{marginTop: "-40.0px", marginLeft: "600px"}}>
      <p>{womb02}</p>
      </section>
      <section className="cms" style={{marginTop: "-40.0px", marginLeft: "830px"}}>
      <p>{medical02}</p>
      </section>
      </section>
      {/* Male Hormonal Assay Data*/}


      {/* Male Hormonal Assay Data*/}
      <section style={{marginTop: "8px"}}>
      <section className="cms" style={{marginLeft: "114px"}}>
      <p>{sperm03}</p>
      </section>
      <section className="cms" style={{marginTop: "-40.0px", marginLeft: "345px"}}>
      <p>{ovarian03}</p>
      </section>
      <section className="cms" style={{marginTop: "-40.0px", marginLeft: "600px"}}>
      <p>{womb03}</p>
      </section>
      <section className="cms" style={{marginTop: "-40.0px", marginLeft: "830px"}}>
      <p>{medical03}</p>
      </section>
      </section>
      {/* Male Hormonal Assay Data*/}


      <div>
        <div className="crp">
     <h4><strong>Conclusion:</strong> {conclusion}  </h4>
     <br />
     <h4><strong>Remark:</strong> {remark} </h4>
     <br />
     <h4><strong>Plan:</strong> {plan} </h4>
     <br />
     <span className="embryoFreeze">
     <p><strong>EMBRYO FREEZING DISCUSSED</strong></p>
     <input type="checkbox" 
      defaultChecked={isYes}
      onChange={(e) => checkBoxYes(e)}  
      name={id}
     />
     </span>
     <br />
        <i>{new Date(timeStamp.seconds * 1000).toLocaleString()}</i>
     </div>
    <p className="signData">{reported}</p>
     <span className="sign">
     <p style={{marginRight : "20px"}}><strong>DR. SIGNATURE</strong></p>
     </span>
     </div>

      </div>
    </div>
    </div>

    <div className="buttonflex">
    <div >
      {/* Our lovely button */}
      <button
      type='button'
      className='btn-pri'
      ><EditTrackReview id={id} reviewHeader1={reviewHeader1} reviewHeader2={reviewHeader2}  reviewHeader3={reviewHeader3}  reviewHeader4={reviewHeader4}  sperm={sperm}  sperm01={sperm01}  sperm02={sperm02}  sperm03={sperm03}  ovarian={ovarian}  ovarian01={ovarian01}  ovarian02={ovarian02}  ovarian03={ovarian03}  womb={womb}  womb01={womb01}  womb02={womb02}  womb03={womb03}  medical={medical} medical01={medical01}  medical02={medical02}  medical03={medical03}  conclusion={conclusion}  remark={remark}  plan={plan}  reported={reported} /></button> 
      </div>
    </div>
    </>
)}
      {/* Mapped Area */}

      {/* Ending Div */}
      </div>

      {/* Modal */}
<div className="modal fade" id="trackRevsModal" tabindex="-1" aria-labelledby="trackRevsModalLabel" aria-hidden="true">
 <div className="modal-dialog modal-xl modal-dialog-scrollable">
 <form className="d-flex" onSubmit={submitTask}>
   <div className="modal-content">
     <div className="modal-header">
       <h1 className="modal-title fs-5" id="trackRevsModalLabel">Review Of Result</h1>
       <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     </div>
     <div className="modal-body">
     
     <form>
     <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">SPERM, OVARIAN, WOMB, MEDICAL HEADER</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Sperm Quality Header"
      list="reviewHeader"
      onChange={(e) => setReviewHeader01(e.target.value)}
    /> 
    <datalist id="reviewHeader">
    {reviewHeader.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Ovarian Function Header"
      list="reviewHeader"
      onChange={(e) => setReviewHeader02(e.target.value)}
    /> 
    <datalist id="reviewHeader">
    {reviewHeader.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Womb Integrity Header"
      list="reviewHeader"
      onChange={(e) => setReviewHeader03(e.target.value)}
    /> 
    <datalist id="reviewHeader">
    {reviewHeader.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Medical Fitness Header"
      list="reviewHeader"
      onChange={(e) => setReviewHeader04(e.target.value)}
    /> 
    <datalist id="reviewHeader">
    {reviewHeader.map((op)=><option>{op}</option>)}
    </datalist>
    </div>   




    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">ROW 1A, 1B, 1C, 1D VALUES</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Sperm Quality"
      list="spermQuality "
      onChange={(e) => setCreateSperm(e.target.value)}
    /> 
    <datalist id="spermQuality ">
    {spermQuality.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Ovarian Function"
      list="ovarianFunction"
      onChange={(e) => setCreateOvarian(e.target.value)}
    /> 
    <datalist id="ovarianFunction">
    {ovarianFunction.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Womb Integrity"
      list="wombIntegrity"
      onChange={(e) => setCreateWomb(e.target.value)}
    /> 
    <datalist id="wombIntegrity">
    {wombIntegrity.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Medical Fitness"
      list="medicalFitness"
      onChange={(e) => setCreateMedical(e.target.value)}
    /> 
    <datalist id="medicalFitness">
    {medicalFitness.map((op)=><option>{op}</option>)}
    </datalist>
    </div>   



    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">ROW 2A, 2B, 2C, 2D VALUES</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Sperm Quality"
      list="spermQuality "
      onChange={(e) => setCreateSperm01(e.target.value)}
    /> 
    <datalist id="spermQuality ">
    {spermQuality.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Ovarian Function"
      list="ovarianFunction"
      onChange={(e) => setCreateOvarian01(e.target.value)}
    /> 
    <datalist id="ovarianFunction">
    {ovarianFunction.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Womb Integrity"
      list="wombIntegrity"
      onChange={(e) => setCreateWomb01(e.target.value)}
    /> 
    <datalist id="wombIntegrity">
    {wombIntegrity.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Medical Fitness"
      list="medicalFitness"
      onChange={(e) => setCreateMedical01(e.target.value)}
    /> 
    <datalist id="medicalFitness">
    {medicalFitness.map((op)=><option>{op}</option>)}
    </datalist>
    </div>   



    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">ROW 3A, 3B, 3C, 3D VALUES</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Sperm Quality"
      list="spermQuality "
      onChange={(e) => setCreateSperm02(e.target.value)}
    /> 
    <datalist id="spermQuality ">
    {spermQuality.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Ovarian Function"
      list="ovarianFunction"
      onChange={(e) => setCreateOvarian02(e.target.value)}
    /> 
    <datalist id="ovarianFunction">
    {ovarianFunction.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Womb Integrity"
      list="wombIntegrity"
      onChange={(e) => setCreateWomb02(e.target.value)}
    /> 
    <datalist id="wombIntegrity">
    {wombIntegrity.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Medical Fitness"
      list="medicalFitness"
      onChange={(e) => setCreateMedical02(e.target.value)}
    /> 
    <datalist id="medicalFitness">
    {medicalFitness.map((op)=><option>{op}</option>)}
    </datalist>
    </div>   



    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">ROW 4A, 4B, 4C, 4D VALUES</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Sperm Quality"
      list="spermQuality "
      onChange={(e) => setCreateSperm03(e.target.value)}
    /> 
    <datalist id="spermQuality ">
    {spermQuality.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Ovarian Function"
      list="ovarianFunction"
      onChange={(e) => setCreateOvarian03(e.target.value)}
    /> 
    <datalist id="ovarianFunction">
    {ovarianFunction.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Womb Integrity"
      list="wombIntegrity"
      onChange={(e) => setCreateWomb03(e.target.value)}
    /> 
    <datalist id="wombIntegrity">
    {wombIntegrity.map((op)=><option>{op}</option>)}
    </datalist>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Medical Fitness"
      list="medicalFitness"
      onChange={(e) => setCreateMedical03(e.target.value)}
    /> 
    <datalist id="medicalFitness">
    {medicalFitness.map((op)=><option>{op}</option>)}
    </datalist>
    </div>   





    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">CONCLUSION</span>
  </div>
    <textarea
      type="text"
      className="form-control"
      placeholder="enter conclusion"
      onChange={(e) => setCreateConclusion(e.target.value)}
      size="3080"
    />
       </div> 


    
       <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">REMARK</span>
  </div>
<textarea
      type="text"
      className="form-control"
      placeholder="enter remarks"
      onChange={(e) => setCreateRemark(e.target.value)}
      size="3080"
    />
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">PLAN</span>
  </div>
<textarea
      type="text"
      className="form-control"
      placeholder="enter plan"
      onChange={(e) => setCreatePlan(e.target.value)}
      size="3080"
    />
    </div> 


   <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">REPORTED BY</span>
  </div>
<input
      type="text"
      className="form-control"
      placeholder="reported by.."
      onChange={(e) => setCreateReport(e.target.value)}
      size="3080"
    />
      </div> 
      </form>

    
     </div>
     <div className="modal-footer">
       <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <button type="submit" className="btn btn-primary">Add Reviews</button>
     </div>
   </div>
   </form>
 </div>
</div>
  

</>
  )
}

export default TrackReview