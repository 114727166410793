import "./sidebar.scss"


import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import StreamIcon from '@mui/icons-material/Stream';
import InsightsIcon from '@mui/icons-material/Insights';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Person3Icon from '@mui/icons-material/Person3';
import LogoutIcon from '@mui/icons-material/Logout';
import { ChatOutlined } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import userLogOut from "../../auth/UserLogout";
import logo from "../../bgImg/logo.png"



const Sidebar = () => {

  const {dispatch} = useContext(DarkModeContext)


     // Logout Function defined
     const navigate = useNavigate();
     const { error, logOut } = userLogOut();
   
   
   const handleLogOut = async () => {
     await logOut();
   
     if (!error) {
       navigate("/login")
     }
   };


  return (
    <div className='sidebar sticky-xl-top'>
      <div className="top">
      <Link to="/" style={{ textDecoration: "none" }}>
      <span className="logo">
        <img src={logo} alt="logo" />
        Fertilizon
        </span>
      </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/" style={{ textDecoration: "none" }}>
          <li>
          <DashboardIcon className="icon"/>
            <span>Dashboard</span> 
          </li>
          </Link>
          
          <p className="title">Lists</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
          <li>
            <PersonIcon className="icon"/>
          <span>Clients</span> 
          </li>
          </Link>


          <Link to="/users/new" style={{ textDecoration: "none" }}>
          <li>
          <HowToRegIcon className="icon"/>
          <span>Registration</span> 
          </li>
          </Link>

          <li>
            <StreamIcon className="icon"/>
          <span>Incidents</span> 
          </li>

          <p className="title">Alert</p>
          <Link to="#" style={{ textDecoration: "none" }}>
          <li>
            <InsightsIcon className="icon"/>
          <span>Stats</span> 
          </li>
          </Link>

          <Link to="/room" style={{ textDecoration: "none" }}>
          <li>
            <HourglassTopIcon className="icon"/>
          <span>Waiting Room</span> 
          </li>
          </Link>

          
          <Link to="/chat" style={{ textDecoration: "none" }}>
          <li>
            <ChatOutlined className="icon"/>
          <span>Chat</span> 
          </li>
          </Link>
              {/* Notification route used here */}
          <Link to="" style={{ textDecoration: "none" }}>
          <li>
            <EventAvailableIcon className="icon"/>
          <span>Events</span> 
          </li>
          </Link>

          <p className="title">Services</p>
          <li>
          <SummarizeIcon className="icon"/>
          <span>MIS</span> 
          </li>

          <p className="title">Users</p>
          <li>
            <Person3Icon className="icon"/>
          <span>Profile</span> 
          </li>
          <li>
          <LogoutIcon className="icon"
              onClick={handleLogOut}
              type='button'
          />
          <span
           onClick={handleLogOut}
           type='button'
          >Logout</span> 
          </li>
        </ul>
      </div>


      <div className="bottom">
          <div className="colorOption" onClick={()=> dispatch({type:"LIGHT"})}></div>
          <div className="colorOption" onClick={()=> dispatch({type:"DARK"})}></div>
      </div>


      </div>

  )
}

export default Sidebar