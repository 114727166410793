import "./cryopreservations.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import Cryop from './../../components/cryop/Cryop';
import Embryo from "../../components/embryo/Embryo";
import Sperm from "../../components/sperm/Sperm";
import EmbryoReport from "../../components/embryoreport/EmbryoReport";


const CryoPreservations = () => {
  return (
    <div className='cryopreservations'>
      <Sidebar/>
      <div className="cryopreservationsContainer">
      <Navbar/>
      <div className="cryops">
      <Cryop/>
      </div>
      <div className="embr">
        <EmbryoReport/>
      </div>
      <div className="emb">
        <Embryo/>
      </div>
      <div className="spms">
        <Sperm/>
      </div>
      </div>
      </div>
  )
}

export default CryoPreservations