import "./tments.scss"

const Tments = () => {
  return (
    <div className="tments">
    <h1>Treatments</h1>
    </div>
  )
}

export default Tments