import "./chatbar.scss"
import NavChatbar from './../navchatbar/Navchatbar';
import Searchchat from './../searchchat/Searchchat';
import SChats from './../schats/Schats';


const Chatbar = () => {
  return (
    <div className="chatbar">
    <NavChatbar/>
    <Searchchat/>
    <SChats/>
    </div>
  )
}

export default Chatbar