import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { db } from '../../firebase';

const EditEmbryo = ({id, desc, dev, grade, quality, device, straw, storage, dateFrozen, dateThawed, status}) => {

  const [createDesc, setCreateDesc] = useState(desc)
  const [createDev, setCreateDev] = useState(dev)
  const [createGrade, setCreateGrade] = useState(grade)
  const [createQuality, setCreateQuality] = useState(quality)
  const [createDevice, setCreateDevice] = useState(device)
  const [createStraw, setCreateStraw] = useState(straw)
  const [createStorage, setCreateStorage] = useState(storage)
  const [createDateThawed, setCreateDateThawed] = useState(dateThawed)
  const [createDateFrozen, setCreateDateFrozen] = useState(dateFrozen)
  const [createStatus, setCreateStatus] = useState(status)


  const {userId} = useParams();

  const updateData = async (e) => {
    e.preventDefault()
    try {
      const taskDocument = doc(db, "androcare/" + userId , "embryo", id);
      await updateDoc(taskDocument, {
          desc: createDesc,
          dev: createDev,
          grade: createGrade,
          quality: createQuality,
          device: createDevice,
          straw: createStraw,
          storage: createStorage,
          dateThawed : createDateThawed,
          dateFrozen : createDateFrozen,
          status: createStatus,
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }
  

  return (
    <>
    {/* Modal Button */}
    <button
    type="button"
    className="btn btn-primary btn-sm"
    data-bs-toggle="modal"
    data-bs-target={`#id${id}`}>
    <EditNoteIcon/>
  </button>

    
  <div
    className="modal fade"
    id={`id${id}`}
    tabIndex="-1"
    aria-labelledby="editLabel"
    aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="editLabel" style={{color:"grey"}}>
            Update EmbyoPresv</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">


        <input
     type="text"
     className="form-control"
     placeholder="enter dev stage"
     defaultValue={createDev}
     onChange={(e) => setCreateDev(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     className="form-control"
     placeholder="enter description"
     defaultValue={createDesc}
     onChange={(e) => setCreateDesc(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter grade"
     className="form-control"
     defaultValue={createGrade}
     onChange={(e) => setCreateGrade(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter quality"
     className="form-control"
     defaultValue={createQuality}
     onChange={(e) => setCreateQuality(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter devive"
     className="form-control"
     defaultValue={createDevice}
     onChange={(e) => setCreateDevice(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter straw"
     className="form-control"
     defaultValue={createStraw}
     onChange={(e) => setCreateStraw(e.target.value)}
     size="3080"
   />
   <input
     type="text"
     placeholder="enter storage"
     className="form-control"
     defaultValue={createStorage}
     onChange={(e) => setCreateStorage(e.target.value)}
     size="3080"
   />

<div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">DATE THAWED</span>
  </div>
  <input
      type="date"
      className="form-control"
      defaultValue={createDateThawed}
      onChange={(e) => setCreateDateThawed(e.target.value)}
    /> 
    </div>     


<div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">DATE FROZEN</span>
  </div>
  <input
      type="date"
      className="form-control"
      defaultValue={dateFrozen}
      onChange={(e) => setCreateDateFrozen(e.target.value)}
    /> 
    </div> 


   <input
     type="text"
     placeholder="set status"
     className="form-control"
     defaultValue={createStatus}
     onChange={(e) => setCreateStatus(e.target.value)}
     size="3080"
   />
   

 
        

        </div>

          <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={e => updateData(e)}
          >Update EmbryoPresv</button>
        </div>
      </div>
    </div>
  </div>

</>
  )
}

export default EditEmbryo