import "./bell.scss";
import Sidebar from "../../patients/sidebar/Sidebar";
import Navbar from "../../patients/navbar/Navbar";
import BellClients from './../../components/bellclients/BellClients';
import TrackBell from "../../components/trackbell/TrackBell";

const Bell = () => {
  return (
    <div className='bell'>
    <Sidebar/>
    <div className="bellContainer">
    <Navbar/>
    <div className="bcl">
    <BellClients/>
    </div>
    <div className="trackclients">
      <TrackBell/>
    </div>
    </div>
    </div>
  )
}

export default Bell