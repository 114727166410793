import "./invgs.scss";


const Invgs = () => {
  return (
    <div className="invgs">
      <h1>Investigations</h1>
      </div>
  )
}

export default Invgs