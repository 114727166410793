import "./reporturinalysis.scss";
import { addDoc, collection, getDocs, query, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PreviewIcon from '@mui/icons-material/Preview';
import EditReportUrinalysis from "./EditReportUrinalysis";

const ReportUrinalysis = () => {

  const urinalysisTitles = ["URINALYSIS REPORT"]

  const UmacroscopyHeader = ["Macroscopy"]

  const UmicroscopyHeader = ["Microscopy"]

  const Ucrow1listings = ["Colour", "Appearance", "Specific Gravity", "pH", "Leukocytes", "Nitrite", "Blood", "Protein (mg/dl)", "Glucose (mg/dl)", "Ketone", "Urobilinogen (mg/dl)", "Bilirubin"]

  const Ucrow2listings = ["Amber Turbid", "1.030", "6.0", "Trace", "Negative"]

  const [tasks, setTasks] = useState([]);
  const [urinalysisTitle, setUrinalysisTitle] = useState("")
  const [macroscopyheader, setMacroscopyHeader] = useState("")
  const [macroscopycell01, setMacroscopyCell01] = useState("")
  const [macroscopyvalue01, setMacroscopyValue01] = useState("")
  const [microscopyheader, setMicroscopyHeader] = useState("")
  const [microscopycell01, setMicroscopyCell01] = useState("")
  const [microscopycell02, setMicroscopyCell02] = useState("")
  const [microscopycell03, setMicroscopyCell03] = useState("")
  const [microscopycell04, setMicroscopyCell04] = useState("")
  const [microscopycell05, setMicroscopyCell05] = useState("")
  const [microscopycell06, setMicroscopyCell06] = useState("")
  const [microscopycell07, setMicroscopyCell07] = useState("")
  const [microscopycell08, setMicroscopyCell08] = useState("")
  const [microscopycell09, setMicroscopyCell09] = useState("")
  const [microscopycell10, setMicroscopyCell10] = useState("")
  const [microscopyvalue01, setMicroscopyValue01] = useState("")
  const [microscopyvalue02, setMicroscopyValue02] = useState("")
  const [microscopyvalue03, setMicroscopyValue03] = useState("")
  const [microscopyvalue04, setMicroscopyValue04] = useState("")
  const [microscopyvalue05, setMicroscopyValue05] = useState("")
  const [microscopyvalue06, setMicroscopyValue06] = useState("")
  const [microscopyvalue07, setMicroscopyValue07] = useState("")
  const [microscopyvalue08, setMicroscopyValue08] = useState("")
  const [microscopyvalue09, setMicroscopyValue09] = useState("")
  const [microscopyvalue10, setMicroscopyValue10] = useState("")
  const [createDate, setCreateDate] = useState("")
  const [checkedBy, setCheckedby] = useState("")


  const {userId} = useParams();
  
  const collectionRef = collection(db, "androcare/" + userId , "reportUrinalysis")

  useEffect(()=>{ 
    const getTasks = async () => {
      const q = query(collectionRef)
      await getDocs(q).then((task)=>{
       let tasksData = task.docs.map((doc) => ({...doc.data(),
      id: doc.id}))
      setTasks(tasksData)
      }).catch((err) =>{
        console.log(err)
      })
    }
    getTasks()
  }, [])

   // Add Task Handler
   const submitTask = async (e) => {
    e.preventDefault();  
    try {
      await addDoc(collectionRef, {
        durinalysisTitle : urinalysisTitle,
        dmacroscopyheader : macroscopyheader,
        dmacroscopycell01 : macroscopycell01,
        dmacroscopyvalue01 : macroscopyvalue01,
        dmicroscopyheader : microscopyheader,
        dmicroscopycell01 : microscopycell01,
        dmicroscopycell02 : microscopycell02,
        dmicroscopycell03 : microscopycell03,
        dmicroscopycell04 : microscopycell04,
        dmicroscopycell05 : microscopycell05,
        dmicroscopycell06 : microscopycell06,
        dmicroscopycell07 : microscopycell07,
        dmicroscopycell08 : microscopycell08,
        dmicroscopycell09 : microscopycell09,
        dmicroscopycell10 : microscopycell10,
        dmicroscopyvalue01 : microscopyvalue01,
        dmicroscopyvalue02 : microscopyvalue02,
        dmicroscopyvalue03 : microscopyvalue03,
        dmicroscopyvalue04 : microscopyvalue04,
        dmicroscopyvalue05 : microscopyvalue05,
        dmicroscopyvalue06 : microscopyvalue06,
        dmicroscopyvalue07 : microscopyvalue07,
        dmicroscopyvalue08 : microscopyvalue08,
        dmicroscopyvalue09 : microscopyvalue09,
        dmicroscopyvalue10 : microscopyvalue10,
        date : createDate,
        checkedby : checkedBy,
        timeStamp: serverTimestamp()
      })
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
    <div className='reporturinalysis'>
          <p>URINALYSIS</p>

        <hr />

        {/* Modal Button */}
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#urinalysisModal">
        Add URINALYSIS
        </button>   


  {/* Mapped Area */}
  {tasks.map(({id,durinalysisTitle,dmacroscopyheader,dmacroscopycell01,dmacroscopyvalue01,dmicroscopyheader,dmicroscopycell01,dmicroscopycell02,dmicroscopycell03,dmicroscopycell04,dmicroscopycell05,dmicroscopycell06,dmicroscopycell07,dmicroscopycell08,dmicroscopycell09,dmicroscopycell10,dmicroscopyvalue01,dmicroscopyvalue02,dmicroscopyvalue03,dmicroscopyvalue04,dmicroscopyvalue05,dmicroscopyvalue06,dmicroscopyvalue07,dmicroscopyvalue08,dmicroscopyvalue09,dmicroscopyvalue10,date,checkedby})=>
    <>
    <div className="coverageury">
    <div className="overallury" key={id}>
    <div className="headerury">
    </div>
    <div className="contentury">

      {/* urinalysis format */}
      <section style={{marginTop: "8px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <u><p style={{fontWeight : "bold"}}>{dmacroscopyheader}</p></u>
     </section>
      </section>
     {/* urinalysis format */}


      {/* urinalysis format */}
      <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{dmacroscopycell01}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmacroscopyvalue01}</p>
      </section>
      </section>
     {/* urinalysis format */}


    {/* urinalysis format */}
    <section style={{marginTop: "8px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <u><p style={{fontWeight : "bold"}}>{dmicroscopyheader}</p></u>
     </section>
      </section>
     {/* urinalysis format */}



      {/* urinalysis format */}
      <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{dmicroscopycell01}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyvalue01}</p>
      </section>
      </section>
     {/* urinalysis format */}


      {/* urinalysis format */}
      <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{dmicroscopycell02}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyvalue02}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{dmicroscopycell03}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyvalue03}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{dmicroscopycell04}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyvalue04}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{dmicroscopycell05}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyvalue05}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{dmicroscopycell06}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyvalue06}</p>
      </section>
      </section>
     {/* urinalysis format */}


       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{dmicroscopycell07}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyvalue07}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{dmicroscopycell08}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyvalue08}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{dmicroscopycell09}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyvalue09}</p>
      </section>
      </section>
     {/* urinalysis format */}

       {/* urinalysis format */}
       <section style={{marginTop: "-38px"}}>
      <section className="ury" style={{marginLeft : "18px"}}>
      <p>{dmicroscopycell10}</p>
      </section>
      <section className="ury" style={{marginTop: "-41.0px", marginLeft: "230px"}}>
      <p>{dmicroscopyvalue10}</p>
      </section>
      </section>
     {/* urinalysis format */}

     </div>
    </div>
    </div>



    <div className="buttonflex">
    <div >
      {/* Our lovely button */}
      <button
      type='button'
      className='btn-pri'
      ><EditReportUrinalysis id={id} durinalysisTitle={durinalysisTitle} dmacroscopyheader={dmacroscopyheader}  dmacroscopycell01={dmacroscopycell01}  dmacroscopyvalue01={dmacroscopyvalue01}  dmicroscopyheader={dmicroscopyheader}  dmicroscopycell01={dmacroscopyvalue01}  dmicroscopycell02={dmicroscopycell02}  dmicroscopycell03={dmicroscopycell03}  dmicroscopycell04={dmicroscopycell04}  dmicroscopycell05={dmicroscopycell05}  dmicroscopycell06={dmicroscopycell06}  dmicroscopycell07={dmicroscopycell07}  dmicroscopycell08={dmicroscopycell08}  dmicroscopycell09={dmicroscopycell09}  dmicroscopycell10={dmicroscopycell10}  dmicroscopyvalue01={dmicroscopyvalue01}  dmicroscopyvalue02={dmicroscopyvalue02}  dmicroscopyvalue03={dmicroscopyvalue03}  dmicroscopyvalue04={dmicroscopyvalue04}  dmicroscopyvalue05={dmicroscopyvalue05}  dmicroscopyvalue06={dmicroscopyvalue06}  dmicroscopyvalue07={dmicroscopyvalue07}  dmicroscopyvalue08={dmicroscopyvalue08}  dmicroscopyvalue09={dmicroscopyvalue09}  dmicroscopyvalue10={dmicroscopyvalue10}   date={date} checkedby={checkedby} /></button> 
      </div>

      {/* Our lovely button */}
    <div>
      <Link to={`/users/${userId}/reports/ury/${id}`}>
      <button
      type='button'
      className='btn-prime'
      >
        <PreviewIcon/>
      </button>
    </Link>
      </div>
    </div>

    </>
)}
        {/* Ending Div */}
        </div>

   {/* Modal */}
 <div className="modal fade" id="urinalysisModal" tabindex="-1" aria-labelledby="urinalysisModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl modal-dialog-scrollable">
  <form className="d-flex" onSubmit={submitTask}>
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="urinalysisModalLabel">URINALYSIS REPORT</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">

  <form>

  <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-01">URINALYSIS TITLE</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter URINALYSIS Report Title"
      list="urinalysisTitles"
      onChange={(e) => setUrinalysisTitle(e.target.value)}
    /> 
    <datalist id="urinalysisTitles">
    {urinalysisTitles.map((op)=><option>{op}</option>)}
    </datalist>
    </div>    

    <input
      type="date"
      className="form-control"
      onChange={(e) => setCreateDate(e.target.value)}
    /> 

  <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-02">MACROSCOPY HEADER</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Macroscopy Header"
      list="UmacroscopyHeader"
      onChange={(e) => setMacroscopyHeader(e.target.value)}
    /> 
    <datalist id="UmacroscopyHeader">
    {UmacroscopyHeader.map((op)=><option>{op}</option>)}
    </datalist>
    </div>    


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-03">Macroscopy Row1AData and Row1BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row1AData"
      list="Ucrow1listings"
      onChange={(e) => setMacroscopyCell01(e.target.value)}
    />
     <datalist id="Ucrow1listings">
    {Ucrow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row1BData"
      list="Ucrow2listings"
      onChange={(e) => setMacroscopyValue01(e.target.value)}
    />
     <datalist id="Ucrow2listings">
    {Ucrow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div>  

    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-04">MICROSCOPY HEADER</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Microscopy Header"
      list="UmicroscopyHeader"
      onChange={(e) => setMicroscopyHeader(e.target.value)}
    /> 
    <datalist id="UmicroscopyHeader">
    {UmicroscopyHeader.map((op)=><option>{op}</option>)}
    </datalist>
    </div>   

    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-05">Microscopy Row1AData and Row1BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row1AData"
      list="Ucrow1listings"
      onChange={(e) => setMicroscopyCell01(e.target.value)}
    />
     <datalist id="Ucrow1listings">
    {Ucrow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row1BData"
      list="Ucrow2listings"
      onChange={(e) => setMicroscopyValue01(e.target.value)}
    />
     <datalist id="Ucrow2listings">
    {Ucrow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div>  


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-06">Microscopy Row2AData and Row2BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row1AData"
      list="Ucrow1listings"
      onChange={(e) => setMicroscopyCell02(e.target.value)}
    />
     <datalist id="Ucrow1listings">
    {Ucrow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row1BData"
      list="Ucrow2listings"
      onChange={(e) => setMicroscopyValue02(e.target.value)}
    />
     <datalist id="Ucrow2listings">
    {Ucrow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div>  


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-07">Microscopy Row3AData and Row3BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row3AData"
      list="Ucrow1listings"
      onChange={(e) => setMicroscopyCell03(e.target.value)}
    />
     <datalist id="Ucrow1listings">
    {Ucrow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row3BData"
      list="Ucrow2listings"
      onChange={(e) => setMicroscopyValue03(e.target.value)}
    />
     <datalist id="Ucrow2listings">
    {Ucrow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 



    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-08">Microscopy Row4AData and Row4BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row4AData"
      list="Ucrow1listings"
      onChange={(e) => setMicroscopyCell04(e.target.value)}
    />
     <datalist id="Ucrow1listings">
    {Ucrow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row4BData"
      list="Ucrow2listings"
      onChange={(e) => setMicroscopyValue04(e.target.value)}
    />
     <datalist id="Ucrow2listings">
    {Ucrow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-09">Microscopy Row5AData and Row5BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row5AData"
      list="Ucrow1listings"
      onChange={(e) => setMicroscopyCell05(e.target.value)}
    />
     <datalist id="Ucrow1listings">
    {Ucrow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row5BData"
      list="Ucrow2listings"
      onChange={(e) => setMicroscopyValue05(e.target.value)}
    />
     <datalist id="Ucrow2listings">
    {Ucrow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-10">Microscopy Row6AData and Row6BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row6AData"
      list="Ucrow1listings"
      onChange={(e) => setMicroscopyCell06(e.target.value)}
    />
     <datalist id="Ucrow1listings">
    {Ucrow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row6BData"
      list="Ucrow2listings"
      onChange={(e) => setMicroscopyValue06(e.target.value)}
    />
     <datalist id="Ucrow2listings">
    {Ucrow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-11">Microscopy Row7AData and Row7BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row7AData"
      list="Ucrow1listings"
      onChange={(e) => setMicroscopyCell07(e.target.value)}
    />
     <datalist id="Ucrow1listings">
    {Ucrow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row7BData"
      list="Ucrow2listings"
      onChange={(e) => setMicroscopyValue07(e.target.value)}
    />
     <datalist id="Ucrow2listings">
    {Ucrow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-12">Microscopy Row8AData and Row8BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row8AData"
      list="Ucrow1listings"
      onChange={(e) => setMicroscopyCell08(e.target.value)}
    />
     <datalist id="Ucrow1listings">
    {Ucrow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row8BData"
      list="Ucrow2listings"
      onChange={(e) => setMicroscopyValue08(e.target.value)}
    />
     <datalist id="Ucrow2listings">
    {Ucrow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 


    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-13">Microscopy Row9AData and Row9BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row9AData"
      list="Ucrow1listings"
      onChange={(e) => setMicroscopyCell09(e.target.value)}
    />
     <datalist id="Ucrow1listings">
    {Ucrow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row9BData"
      list="Ucrow2listings"
      onChange={(e) => setMicroscopyValue09(e.target.value)}
    />
     <datalist id="Ucrow2listings">
    {Ucrow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 

    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair-14">Microscopy Row10AData and Row10BData</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Enter Row10AData"
      list="Ucrow1listings"
      onChange={(e) => setMicroscopyCell10(e.target.value)}
    />
     <datalist id="Ucrow1listings">
    {Ucrow1listings.map((op)=><option>{op}</option>)}
    </datalist>
      <input
      type="text"
      className="form-control"
      placeholder="Enter Row10BData"
      list="Ucrow2listings"
      onChange={(e) => setMicroscopyValue10(e.target.value)}
    />
     <datalist id="Ucrow2listings">
    {Ucrow2listings.map((op)=><option>{op}</option>)}
    </datalist>
    </div> 

    <div class="input-group">
  <div class="input-group-prepend">
    <span class="input-group-text" id="pair">CHECKED BY</span>
  </div>
  <input
      type="text"
      className="form-control"
      placeholder="Checked by"
      onChange={(e) => setCheckedby(e.target.value)}
    /> 
    </div>  
    

    </form>

    
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary">Add Data</button>
      </div>
    </div>
    </form>
  </div>
</div>

        </>
  )
}

export default ReportUrinalysis