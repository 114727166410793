import "./calender.scss";
import Fullcalendar from '@fullcalendar/react';
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useState } from "react";
import { v4 as uuid } from 'uuid';
import moment from "moment";

const Calendar = () => {
  const [events, setEvents] = useState([]);

  const handleSelect = (info) => {
    const { start, end } = info;
    const eventNamePrompt = prompt("Enter, your preferred appointment");
    if (eventNamePrompt) {
      setEvents([
        ...events,
        {
          start,
          end,
          title: eventNamePrompt,
          id: uuid(),
        },
      ]);
    }
  };

  const EventItem = ({ info }) => {
    const { event } = info;
    return (
      <div className="eventCalendar">
        <ul>
          <li>
            {event.title.length > 20 ? `${event.title.substring(0, 20)}...` : event.title}
          </li>
          <p>{moment(event.start).format('LLL')}</p>
        </ul>
      </div>
    );
  };


  return (
    <div className="calendar">
    <Fullcalendar
      editable
      selectable
      events={events}
      select={handleSelect}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView={"dayGridMonth"}
      headerToolbar={{
        start: "today prev,next", // will normally be on the left. if RTL, will be on the right
        center: "title",
        end: "dayGridMonth,timeGridWeek,timeGridDay", // will normally be on the right. if RTL, will be on the left
      }}
      eventContent={(info) => <EventItem info={info} />}
      height={"90vh"}
    />
  </div>
  )
};

export default Calendar