import "./pcdures.scss";

const Pcdures = () => {
  return (
    <div className="pcdures">
      <h1>Procedures</h1>
      </div>
  )
}

export default Pcdures